import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AutomataDevelopmentService, EventTriggerDefinitionInterface } from '@vendasta/automata';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';
import { take } from 'rxjs/operators';
import { EventTriggerDefinitionFormComponent } from '../event-trigger-definition-form/event-trigger-definition-form.component';

@Component({
  selector: 'app-create-event-trigger-definition-page',
  templateUrl: './create-event-trigger-definition-page.component.html',
  styleUrls: ['./create-event-trigger-definition-page.component.scss'],
})
export class CreateEventTriggerDefinitionPageComponent {
  @ViewChild('eventTriggerDefinitionForm') eventTriggerDefinitionForm: EventTriggerDefinitionFormComponent;
  saveDisabled = false;

  constructor(
    private route: ActivatedRoute,
    private readonly automataDevelopmentService: AutomataDevelopmentService,
    private router: Router,
    private alertService: SnackbarService,
    private cdr: ChangeDetectorRef,
  ) {}

  cancelClicked(value: boolean): void {
    if (value) {
      this.router.navigate(['/automations/event-trigger-definitions/list']);
    }
  }

  saveClicked(value: EventTriggerDefinitionInterface): void {
    if (!value) {
      this.alertService.openErrorSnack(`I don't know how we got here, that's on you to figure out`);
      return;
    }
    this.saveDisabled = true;
    this.automataDevelopmentService
      .createEventTriggerDefinition(value)
      .pipe(take(1))
      .subscribe(
        () => {
          this.saveDisabled = false;
          this.alertService.openSuccessSnack('Event trigger definition successfully created');
          this.cdr.detectChanges();
          this.router.navigate(['/automations/task-definitions/list']);
        },
        (err) => {
          console.error(err);
          this.saveDisabled = false;
          this.alertService.openErrorSnack(err?.error?.message);
          this.cdr.detectChanges();
        },
      );
  }

  import(): void {
    this.eventTriggerDefinitionForm.import();
  }
}
