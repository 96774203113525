<glxy-page>
  <glxy-page-toolbar>
    <glxy-page-nav>
      <glxy-page-nav-button
        previousPageTitle="Event Trigger Definitions"
        [previousPageUrl]="'/automations/event-trigger-definitions/list'"
      />
    </glxy-page-nav>

    <glxy-page-title>Edit Trigger Definition</glxy-page-title>

    <glxy-page-actions>
      <button mat-stroked-button (click)="copyToClipboard()">Copy To Clipboard</button>
    </glxy-page-actions>
  </glxy-page-toolbar>
  <glxy-page-wrapper maxWidth="1160">
    <ng-container *ngIf="eventTriggerDefinition$ | async as eventTriggerDefinition; else loading">
      <app-event-trigger-definition-form
        [eventTriggerDefinition]="eventTriggerDefinition"
        [saveDisabled]="saveDisabled"
        #eventTriggerDefinitionForm
        (cancelClicked)="cancelClicked($event)"
        (saveClicked)="saveClicked($event)"
      />
    </ng-container>
    <ng-template #loading>
      <mat-card appearance="outlined" class="stencil-shimmer" />
    </ng-template>
  </glxy-page-wrapper>
</glxy-page>
