import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import {
  AutomataDevelopmentService,
  EventTriggerDefinitionInterface,
  TaskDefinitionInterface,
  TaskType,
} from '@vendasta/automata';
import { map, switchMap, take } from 'rxjs/operators';
import { TaskDefinitionFormComponent } from '../task-definition-form/task-definition-form.component';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';
import { Observable, of } from 'rxjs';
import { stringToTaskType } from '../conversion';

@Component({
  selector: 'app-create-task-definition-page',
  templateUrl: './create-task-definition-page.component.html',
  styleUrls: ['./create-task-definition-page.component.scss'],
})
export class CreateTaskDefinitionPageComponent implements OnInit {
  @ViewChild('taskDefinitionForm') taskDefinitionForm: TaskDefinitionFormComponent;
  saveDisabled = false;

  sourceEventTriggerDefinition$: Observable<EventTriggerDefinitionInterface>;
  taskType$: Observable<TaskType>;

  constructor(
    private route: ActivatedRoute,
    private readonly automataDevelopmentService: AutomataDevelopmentService,
    private router: Router,
    private alertService: SnackbarService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    const eventTriggerDefinitionId$ = this.route.queryParamMap.pipe(
      take(1),
      map((params) => params.get('eventTriggerDefinitionId')),
    );

    this.sourceEventTriggerDefinition$ = eventTriggerDefinitionId$.pipe(
      take(1),
      switchMap((id) => {
        if (id) {
          return this.automataDevelopmentService.getEventTriggerDefinition(id);
        }
        return of({});
      }),
    );

    this.taskType$ = this.route.queryParamMap.pipe(
      take(1),
      map((params) => stringToTaskType(params.get('taskType'))),
    );
  }

  cancelClicked(value: boolean): void {
    if (value) {
      this.router.navigate(['/automations/task-definitions/list']);
    }
  }

  saveClicked(value: TaskDefinitionInterface): void {
    if (!value) {
      this.alertService.openErrorSnack(`I don't know how we got here, that's on you to figure out`);
      return;
    }
    this.saveDisabled = true;
    this.automataDevelopmentService
      .createTaskDefinition(value)
      .pipe(take(1))
      .subscribe(
        () => {
          this.saveDisabled = false;
          this.alertService.openSuccessSnack('Task definition successfully created');
          this.cdr.detectChanges();
          this.router.navigate(['/automations/task-definitions/list']);
        },
        (err) => {
          console.error(err);
          this.saveDisabled = false;
          this.alertService.openErrorSnack(err?.error?.message);
          this.cdr.detectChanges();
        },
      );
  }

  import(): void {
    this.taskDefinitionForm.import();
  }
}
