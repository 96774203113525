import { CommonModule } from '@angular/common';
import { Component, DestroyRef, EventEmitter, forwardRef, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormGroup,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { RuleInterface } from '@vendasta/automata';
import { GalaxyFormFieldModule } from '@vendasta/galaxy/form-field';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-rule-input',
  templateUrl: './rule-input.component.html',
  styleUrls: ['./rule-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RuleInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RuleInputComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [
    CommonModule,
    GalaxyFormFieldModule,
    FormsModule,
    MatInputModule,
    ReactiveFormsModule,
    MatOptionModule,
    MatSelectModule,
    MatCheckboxModule,
    MatIconModule,
    MatButtonModule,
  ],
})
export class RuleInputComponent implements ControlValueAccessor, Validator {
  @Output() remove: EventEmitter<void> = new EventEmitter<void>();
  comparisonOptions: string[] = [
    'StringExists',
    'StringEquals',
    'StringEqualFold',
    'StringContains',
    'StringContainsFold',
    'NumberEquals',
    'NumberGreaterThan',
    'RandomNumberLessThan',
    // The following comparisons might require special inputs so the developer doesn't have to type an exact date (for example)
    // We don't have use cases for these yet, so we're not implementing them.
    // 'BoolEquals',
    // 'BoolListContains',
    // 'StringListContains',
    // 'StringListContainsAll',
    // 'StringListContainsPrefix',
    // 'StringListContainsPrefixAll',
    // 'StringListContainsFold',
    // 'IsOneOfInStringListFold',
    // 'TimeEquals',
    // 'TimeAfter',
    // 'TimeBefore',
    // 'AlwaysTrue',
    // 'OrderActionEquals',
    // 'ListSizeGreaterThan',
  ];

  fg = new FormGroup({
    selectedComparison: new FormControl<string>(null, [Validators.required]),
    variablePath: new FormControl<string>(null, [Validators.required]),
    value: new FormControl<string>(null),
  });

  constructor(private readonly destroyRef: DestroyRef) {}

  registerOnChange(fn: any): void {
    this.fg.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        map((fg) => {
          const ri: RuleInterface = {
            variablePath: fg.variablePath,
            value: fg.value,
            next: 'start',
            comparison: fg.selectedComparison,
          };
          return ri;
        }),
      )
      .subscribe((value) => {
        fn(value);
      });
  }

  registerOnTouched(fn: any): void {
    this.fg.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(fn);
  }

  writeValue(rule: RuleInterface): void {
    if (rule) {
      this.fg.controls.selectedComparison.setValue(rule.comparison);
      this.fg.controls.variablePath.setValue(rule.variablePath);
      this.fg.controls.value.setValue(rule.value);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  validate(control: AbstractControl): ValidationErrors | null {
    return this.fg.invalid ? { invalid: true } : null;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.fg.disable();
    } else {
      this.fg.enable();
    }
  }

  removeClicked(): void {
    this.remove.emit();
  }
}
