<div class="container">
  <glxy-form-field class="comparisonSelect">
    <glxy-label>Comparison</glxy-label>
    <mat-select [formControl]="fg.controls.selectedComparison">
      <mat-option *ngFor="let comparisonOption of comparisonOptions" [value]="comparisonOption">
        {{ comparisonOption }}
      </mat-option>
    </mat-select>
  </glxy-form-field>
  <glxy-form-field class="pathInput">
    <glxy-label>Path</glxy-label>
    <glxy-label-hint>
      Should be surrounded by curly braces and dot notation. E.G. `{{
        '{'
      }}.account_group.external_identifiers.market_id&#125;`
    </glxy-label-hint>
    <input matInput [formControl]="fg.controls.variablePath" />
  </glxy-form-field>
  <glxy-form-field class="valueInput">
    <glxy-label>value</glxy-label>
    <glxy-label-hint>
      When reloading the values for this control they are wrapped with quotes. I don't know if this is a problem or not
      yet.
    </glxy-label-hint>
    <input matInput [formControl]="fg.controls.value" />
  </glxy-form-field>
  <div class="close">
    <button mat-icon-button (click)="removeClicked()"><mat-icon>close</mat-icon></button>
  </div>
</div>
