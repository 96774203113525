import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { Router } from '@angular/router';
import { TaskType } from '@vendasta/automata';
import { TABLE_DEFINITION, VaFilteredMatTableService, VaTableSortService } from '@vendasta/va-filter2-table';
import { take } from 'rxjs/operators';
import { taskTypeToString } from '../conversion';
import { EventTriggerDefinitionSelectorDialogComponent } from '../create-task-definition-page/event-trigger-definition-selector-dialog/event-trigger-definition-selector-dialog.component';
import { tableDefinitionFactory } from './task-defintions-table/table-model';
import { TaskDefinitionsTableService } from './task-defintions-table/task-definitions-table.service';
import { TaskDefintionsFiltersService } from './task-defintions-table/task-defintions-filters.service';

@Component({
  selector: 'app-task-definitions-list-page',
  templateUrl: './task-definitions-list-page.component.html',
  styleUrls: ['./task-definitions-list-page.component.scss'],
  providers: [
    TaskDefinitionsTableService,
    TaskDefintionsFiltersService,
    {
      provide: TABLE_DEFINITION,
      useFactory: tableDefinitionFactory,
      deps: [TaskDefintionsFiltersService],
    },
    VaFilteredMatTableService,
    VaTableSortService,
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {} },
  ],
})
export class TaskDefinitionsListPageComponent {
  TaskType = TaskType;

  constructor(
    readonly dataService: TaskDefinitionsTableService,
    private dialog: MatDialog,
    private readonly router: Router,
  ) {}

  createTaskDefinition(taskType: TaskType): void {
    if (taskType === TaskType.TASK_TYPE_TRIGGER) {
      const dialogRef = this.dialog.open(EventTriggerDefinitionSelectorDialogComponent);
      dialogRef
        .afterClosed()
        .pipe(take(1))
        .subscribe((result) => {
          if (result?.eventTypeId) {
            this.router.navigate(['/automations/task-definitions/create'], {
              queryParams: { taskType: taskTypeToString(taskType), eventTriggerDefinitionId: result.eventTypeId },
            });
          }
        });
    } else {
      this.router.navigate(['/automations/task-definitions/create'], {
        queryParams: { taskType: taskTypeToString(taskType) },
      });
    }
  }
}
