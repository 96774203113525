import { FilterGroup, FilterService, SearchFilterControl } from '@vendasta/va-filter2';
import { Injectable } from '@angular/core';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';

export interface Filters {
  searchTerm: string;
}

@Injectable()
export class EventTriggerDefinitionsFiltersService {
  filters: FilterGroup = new FilterGroup('event-trigger-definitions');

  constructor(private readonly filterService: FilterService, private readonly snackbarService: SnackbarService) {
    this.filters.addToolbarSection(new SearchFilterControl('searchTerm', 'Search'));
  }
}
