<div>
  <form [formGroup]="form">
    <div class="row">
      <mat-card appearance="outlined" class="base-info">
        <mat-card-title>Base information</mat-card-title>
        <mat-card-content>
          <div class="flex-form">
            <div class="info">
              <glxy-form-field required>
                <glxy-label>ID</glxy-label>
                <input matInput formControlName="id" placeholder="TaskDefinition-" required />
              </glxy-form-field>
              <mat-icon
                glxyTooltip="You have to specify this ID because it needs to be the same for both Demo and Prod. It must start with 'TaskDefinition-' and be some readable value after (e.g. TaskDefinition-account-created-trigger)"
                tooltipTitle="ID"
                [highContrast]="false"
                class="info-icon"
                [inline]="true"
              >
                info_outline
              </mat-icon>
            </div>
            <div class="info">
              <glxy-form-field required>
                <glxy-label>Name</glxy-label>
                <input matInput formControlName="name" required />
                <glxy-error *ngIf="form.controls.name.hasError('required')">Required</glxy-error>
              </glxy-form-field>
              <mat-icon
                glxyTooltip="This is only used to make it easier to look at the data in vstore. This is never shown to a user"
                tooltipTitle="Name"
                [highContrast]="false"
                class="info-icon"
                [inline]="true"
              >
                info_outline
              </mat-icon>
            </div>
            <div class="info">
              <glxy-form-field required>
                <glxy-label>Task Type</glxy-label>
                <mat-select formControlName="taskType" required="true">
                  <mat-option *ngFor="let taskType of taskTypes" [value]="taskType">
                    {{ taskTypeLabels[taskType] }}
                  </mat-option>
                </mat-select>
                <glxy-error *ngIf="form.controls.taskType.hasError('required')">Required</glxy-error>
                <glxy-error *ngIf="form.controls.taskType.hasError('invalid')">Invalid</glxy-error>
              </glxy-form-field>
              <mat-icon
                glxyTooltip="The task type is mainly used to separate the tasks in the ui (e.g. Only showing the user Triggers when they need to select a trigger). It also signifies that actions and triggers need their input params validated against the stream data."
                tooltipTitle="Task Type"
                [highContrast]="false"
                class="info-icon"
                [inline]="true"
              >
                info_outline
              </mat-icon>
            </div>
            <ng-container *ngIf="form.controls.taskType.value === TRIGGER">
              <div class="info">
                <glxy-form-field required>
                  <glxy-label>Event Type ID</glxy-label>
                  <input matInput formControlName="eventTypeId" required />
                  <glxy-error *ngIf="form.controls.eventTypeId.hasError('required')">Required</glxy-error>
                </glxy-form-field>
                <mat-icon
                  glxyTooltip="The event type id is only used for Trigger tasks and indicates the event that will trigger the task."
                  tooltipTitle="Event Type ID"
                  [highContrast]="false"
                  class="info-icon"
                  [inline]="true"
                >
                  info_outline
                </mat-icon>
              </div>

              <div class="info">
                <glxy-form-field>
                  <glxy-label>Event Sub Type ID</glxy-label>
                  <input matInput formControlName="eventSubTypeId" />
                </glxy-form-field>
                <mat-icon
                  glxyTooltip="The event sub type id is only used for Trigger tasks and indicates the sub event that will trigger the task. Optional. Only for events that distinguish themselves with a sub event type on the same parent event."
                  tooltipTitle="Event Sub Type ID"
                  [highContrast]="false"
                  class="info-icon"
                  [inline]="true"
                >
                  info_outline
                </mat-icon>
              </div>
            </ng-container>
            <div class="info">
              <glxy-form-field required>
                <glxy-label>Published State</glxy-label>
                <mat-select
                  required="true"
                  [value]="taskDefinition.publishedState || DRAFT"
                  disabled
                  matTooltip="You need to explicitly publish/unpublish"
                >
                  <mat-option *ngFor="let publishedState of publishedStates" [value]="publishedState">
                    {{ publishedStateLabels[publishedState] }}
                  </mat-option>
                </mat-select>
              </glxy-form-field>
              <mat-icon
                glxyTooltip="Currently, only members of the autobots can publish things. This is because most of our support issues are related to UX so we need to be very careful about getting that part right"
                tooltipTitle="Published State"
                [highContrast]="false"
                class="info-icon"
                [inline]="true"
              >
                info_outline
              </mat-icon>
            </div>
            <div class="info">
              <mat-checkbox formControlName="internal">Internal</mat-checkbox>
              <mat-icon
                glxyTooltip="Only check this if you are building something specifically for VMF. This should be extremely rare. If this is what you are doing, you'll need to read the 'Building VMF triggers and actions' guide in order to proceed."
                tooltipTitle="Internal Task Definitions"
                [highContrast]="false"
                class="info-icon"
                [inline]="true"
              >
                info_outline
              </mat-icon>
            </div>
            <div class="info">
              <mat-checkbox formControlName="availableInDefault">Available in default automations</mat-checkbox>
              <mat-icon
                glxyTooltip="There are some tasks that don't make sense to allow in default automations, such as 'Add Account To List'. What list? All partners have different lists. It wouldn't make sense for us to choose a list for everyone. This should probably be checked, unless you're in the same situation as lists."
                tooltipTitle="Default/Builtin Automations"
                [highContrast]="false"
                class="info-icon"
                [inline]="true"
              >
                info_outline
              </mat-icon>
            </div>
            <div class="info">
              <glxy-form-field required>
                <glxy-label>Supported Contexts</glxy-label>
                <mat-select formControlName="supportedContexts" required="true" multiple="true">
                  <mat-option *ngFor="let supportedContext of supportedContexts" [value]="supportedContext">
                    {{ supportedContextLabels[supportedContext] }}
                  </mat-option>
                </mat-select>
                <glxy-error *ngIf="form.controls.supportedContexts.hasError('required')">Required</glxy-error>
              </glxy-form-field>
              <mat-icon
                glxyTooltip="Most of the original tasks built for automations are only supported by Partners, but as development moves closer to the 'as a service' philosophy, there will be more tasks that are supported by both (CRM tasks for example). Multiple contexts can be supported."
                tooltipTitle="Supported Contexts"
                [highContrast]="false"
                class="info-icon"
                [inline]="true"
              >
                info_outline
              </mat-icon>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
      <mat-card appearance="outlined">
        <mat-card-title>
          Resource
          <glxy-alert type="warning" *ngIf="editMode && resourcesRemoved">
            <strong>Warnings</strong>
            &mdash; Resouces removed when data imported
          </glxy-alert>
        </mat-card-title>
        <mat-card-content>
          <div class="flex-form">
            <div class="info">
              <glxy-form-field>
                <glxy-label>URL</glxy-label>
                <input matInput formControlName="resourceUrl" />
              </glxy-form-field>
              <mat-icon
                glxyTooltip="This is the URL that will be hit when this task is run. This is essentially required for Actions and Filters, but optional (and rare) for Triggers. Needs to start with https://"
                tooltipTitle="URL"
                [highContrast]="false"
                class="info-icon"
                [inline]="true"
              >
                info_outline
              </mat-icon>
            </div>
            <div class="info">
              <glxy-form-field>
                <glxy-label>Method</glxy-label>
                <input matInput formControlName="resourceMethod" />
                <glxy-error *ngIf="form.controls.resourceMethod.hasError('invalid')">
                  Only 'POST' is valid right now
                </glxy-error>
              </glxy-form-field>
              <mat-icon
                glxyTooltip="The method to use when hitting your resource url. The only supported method is POST so type POST into this box."
                tooltipTitle="Method"
                [highContrast]="false"
                class="info-icon"
                [inline]="true"
              >
                info_outline
              </mat-icon>
            </div>
            <div class="info">
              <mat-checkbox formControlName="resourceIsGetter">Does not modify data</mat-checkbox>
              <mat-icon
                glxyTooltip="This is you signing off that your resource does not modify any data if you are using it for a Filter or a Trigger. This is because resources that cause side effects for either of those two tasks doesn't make sense."
                tooltipTitle="Does not modify data."
                [highContrast]="false"
                class="info-icon"
                [inline]="true"
              >
                info_outline
              </mat-icon>
            </div>
            <ng-container *ngFor="let group of resourceRetryOptionsArray().controls; let i = index">
              <div [formGroup]="group" class="flex-form repeated-form">
                <button mat-icon-button (click)="removeRetryOption(i)">
                  <mat-icon inline="true">close</mat-icon>
                </button>

                <glxy-form-field required>
                  <glxy-label>Status Code</glxy-label>
                  <input matInput formControlName="statusCode" required />
                  <glxy-error *ngIf="group.controls.statusCode.hasError('required')">Required</glxy-error>
                </glxy-form-field>
                <glxy-form-field required>
                  <glxy-label>Maximum Attempts</glxy-label>
                  <input matInput formControlName="maximumAttempts" required />
                  <glxy-error *ngIf="group.controls.maximumAttempts.hasError('pattern')">
                    Must be a positive integer
                  </glxy-error>
                  <glxy-error *ngIf="group.controls.maximumAttempts.hasError('required')">Required</glxy-error>
                </glxy-form-field>
              </div>
            </ng-container>
            <button
              mat-stroked-button
              (click)="addRetryOptions()"
              glxyTooltip="By default we will automatically retry hitting your resource multiple times (with a backoff policy) if the result is 5XX. If the result is 4XX we don't retry. If you need to change this default retry policy, you should first learn what the default is then specify something different."
              tooltipTitle="Retry Options."
              [highContrast]="false"
            >
              Add Retry Options
            </button>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="preprocessors">
      <mat-card appearance="outlined" class="base-info">
        <mat-card-title>Preprocessors</mat-card-title>
        <ng-container *ngFor="let group of preProcessorsFormArray().controls; let i = index">
          <div [formGroup]="group" class="flex-form repeated-form">
            <button mat-icon-button (click)="removePreProcessor(i)">
              <mat-icon inline="true">close</mat-icon>
            </button>
            <glxy-form-field required>
              <glxy-label>id</glxy-label>
              <input matInput formControlName="id" required />
            </glxy-form-field>
            <glxy-form-field required>
              <glxy-label>name</glxy-label>
              <input matInput formControlName="name" required />
            </glxy-form-field>
            <glxy-form-field required>
              <glxy-label>Task Definition ID</glxy-label>
              <input matInput formControlName="taskDefinitionId" required />
            </glxy-form-field>
            <div *ngFor="let data of preProcessorDataFormArray(i).controls; let j = index">
              <div [formGroup]="data">
                <glxy-form-field required>
                  <glxy-label>Data Key</glxy-label>
                  <input matInput formControlName="pathKey" required />
                </glxy-form-field>
                <glxy-form-field required>
                  <glxy-label>Data Value</glxy-label>
                  <input matInput formControlName="pathValue" required />
                </glxy-form-field>
                <button mat-icon-button (click)="removePreProcessorKeyValue(i, j)">
                  <mat-icon inline="true">close</mat-icon>
                </button>
              </div>
            </div>
            <div>
              <button mat-stroked-button (click)="addPreProcessorKeyValue(i)">Add data</button>
            </div>
            <div *ngFor="let choices of preProcessorChoicesFormArray(i).controls; let j = index">
              <div *ngIf="j === 0">
                <glxy-form-field>
                  <glxy-label>Default Value</glxy-label>
                  <input matInput [formControl]="group.controls.choices.value.controls.default" />
                  <glxy-hint>default value</glxy-hint>
                </glxy-form-field>
              </div>
              <div [formGroup]="choices">
                <glxy-form-field>
                  <glxy-label>Variable Path</glxy-label>
                  <input matInput formControlName="variablePath" />
                  <glxy-hint>variable path</glxy-hint>
                </glxy-form-field>
                <glxy-form-field>
                  <glxy-label>Comparison</glxy-label>
                  <input matInput formControlName="comparison" />
                  <glxy-hint>comparison</glxy-hint>
                </glxy-form-field>
                <glxy-form-field>
                  <glxy-label>Value</glxy-label>
                  <input matInput formControlName="value" />
                  <glxy-hint>value</glxy-hint>
                </glxy-form-field>
                <glxy-form-field>
                  <glxy-label>Next</glxy-label>
                  <input matInput formControlName="next" />
                  <glxy-hint>next</glxy-hint>
                </glxy-form-field>
                <button mat-icon-button (click)="removePreProcessorRule(i, j)">
                  <mat-icon inline="true">close</mat-icon>
                </button>
              </div>
            </div>
            <div>
              <button mat-stroked-button (click)="addPreProcessorRule(i)">Add rule</button>
            </div>
          </div>
          <div></div>
        </ng-container>
        <button mat-stroked-button (click)="addPreProcessor()">Add Preprocessor</button>
      </mat-card>
    </div>
    <div class="preprocessors" *ngIf="form.controls.taskType.value === TRIGGER">
      <mat-card appearance="outlined">
        <mat-card-title>
          Static Rules to be applied to the event to see if this trigger should run
          <mat-icon
            glxyTooltip="We have multiple trigger task definitions for the same EventTriggerDefinition. These rules allow us to filter the events appropriately for each TaskDefinition. They are like filter rules for a trigger when you are building out the frontend panel."
            tooltipTitle="Static rules to be applied to a trigger"
            [highContrast]="false"
            class="info-icon"
            [inline]="true"
          >
            info_outline
          </mat-icon>
        </mat-card-title>
        <mat-card-content>
          <app-and-or-input formControlName="choices" />
        </mat-card-content>
      </mat-card>
    </div>
    <div class="preprocessors">
      <mat-card appearance="outlined">
        <mat-card-title>
          Static Validation Rules to see if this task definition is valid for the data in the stream
          <mat-icon
            glxyTooltip="It's possible that 'required' isn't good enough for the input param validation (e.g. require one of two fields). This is where you can specify these custom validation rules"
            tooltipTitle="Static validation rules to be applied to the input params"
            [highContrast]="false"
            class="info-icon"
            [inline]="true"
          >
            info_outline
          </mat-icon>
        </mat-card-title>
        <mat-card-content>
          <app-and-or-input formControlName="staticValidationChoices" />
        </mat-card-content>
      </mat-card>
    </div>

    <mat-card class="required-apps-card" appearance="outlined">
      <mat-card-title>
        Required Apps
        <mat-icon
          glxyTooltip="Multiple apps will be evaluated as an OR. If the namespace has access to any of the apps in the list, they will be able to use this step.
IDs will look like 'MP-1234' (app), 'A-1234' (addon) and EDITION-1234' (edition)"
          tooltipTitle="Required Marketplace Apps"
          [highContrast]="false"
          class="info-icon"
          [inline]="true"
        >
          info_outline
        </mat-icon>
        <glxy-alert type="warning" *ngIf="editMode && requiredAppsRemoved">
          <strong>Warnings</strong>
          &mdash; Required Apps removed when data imported
        </glxy-alert>
      </mat-card-title>
      <mat-card-content>
        <div *ngFor="let group of requiredAppsFormArray(); let i = index">
          <button class="remove-app-button" mat-icon-button (click)="removeRequiredApp(i)">
            <mat-icon>close</mat-icon>
          </button>
          <glxy-form-row>
            <glxy-form-field class="col-xs-12 col-sm-6">
              <glxy-label>App / Addon ID</glxy-label>
              <input [formControl]="group.controls.appId" matInput />
            </glxy-form-field>
            <glxy-form-field class="col-xs-12 col-sm-6">
              <glxy-label>Edition ID</glxy-label>
              <input [formControl]="group.controls.editionId" matInput />
            </glxy-form-field>
          </glxy-form-row>
        </div>
      </mat-card-content>
      <button mat-stroked-button (click)="addRequiredApp()">Add App</button>
    </mat-card>

    <mat-card appearance="outlined">
      <mat-card-title>Access Control</mat-card-title>
      <mat-card-content>
        <ng-container *ngFor="let group of accessAttributesFormArray().controls; let i = index">
          <div [formGroup]="group" class="flex-form repeated-form">
            <button mat-icon-button (click)="removeAccessAttribute(i)">
              <mat-icon inline="true">close</mat-icon>
            </button>
            <glxy-form-field required>
              <glxy-label>Access Attribute</glxy-label>
              <input matInput [formControl]="accessAttributesFormArray().controls[i]" required />
              <glxy-error *ngIf="accessAttributesFormArray().controls[i].hasError('required')">Required</glxy-error>
            </glxy-form-field>
          </div>
        </ng-container>
        <button
          mat-stroked-button
          (click)="addAccessAttribute()"
          glxyTooltip="These are the attributes that will be checked on the users' persona to check if they are allowed to see/user a trigger or action. (e.g. If they don't have the 'can_access_marketing' attribute we shouldn't let them send campaigns via automations)"
          tooltipTitle="Access Attributes"
          [highContrast]="false"
        >
          Add Access Attribute
        </button>
        <ng-container *ngFor="let group of requiredSubscriptionFeaturesFormArray().controls; let i = index">
          <div [formGroup]="group" class="flex-form repeated-form">
            <button mat-icon-button (click)="removeRequiredSubscriptionFeatures(i)">
              <mat-icon inline="true">close</mat-icon>
            </button>
            <glxy-form-field required>
              <glxy-label>Required Subscription Feature</glxy-label>
              <input matInput [formControl]="requiredSubscriptionFeaturesFormArray().controls[i]" required />
              <glxy-error *ngIf="requiredSubscriptionFeaturesFormArray().controls[i].hasError('required')">
                Required
              </glxy-error>
            </glxy-form-field>
          </div>
        </ng-container>
        <button
          mat-stroked-button
          (click)="addRequiredSubscriptionFeatures()"
          glxyTooltip="These are the partner subscription features that are required to use this task. The flags for these features are defined in the partner microservice."
          tooltipTitle="Required Subscription Features"
          [highContrast]="false"
        >
          Add Required Subscription Feature
        </button>
      </mat-card-content>
    </mat-card>
    <div class="scopes">
      <mat-card appearance="outlined">
        <mat-card-title>
          Scopes -
          <a
            href="https://vendasta.jira.com/wiki/spaces/AUTO/pages/1724187810/Adding+OAuth+Scopes+to+your+TaskDefinition"
            target="_blank"
          >
            Learn About Scopes Here
          </a>
        </mat-card-title>
        <mat-card-content>
          <div class="flex-form" style="margin-top: 8px">
            <ng-container *ngIf="scopeOptions$ | async as scopeOptions; else loadingScopes">
              <glxy-form-field>
                <glxy-label>Required Scopes</glxy-label>
                <mat-select formControlName="requiredScopes" multiple>
                  <mat-option *ngFor="let scopeOption of scopeOptions" [value]="scopeOption">
                    {{ scopeOption }}
                  </mat-option>
                </mat-select>
              </glxy-form-field>
            </ng-container>
            <ng-template #loadingScopes>Loading Scopes, just wait a sec</ng-template>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="row">
      <mat-card appearance="outlined">
        <mat-card-title class="input-param-title">
          <span style="flex: 1">
            Input Parameters
            <mat-icon
              glxyTooltip="This is how you take the json data that's in the stream and convert it to the json payload that will be sent to your resource. The 'Path Value' extracts the data from the stream and inserts it into the key that is defined by 'Path Key'. Use the plus button to add common values."
              tooltipTitle="Input parameters."
              [highContrast]="false"
              class="info-icon"
              [inline]="true"
            >
              info_outline
            </mat-icon>
          </span>
          <button (click)="openAddInputParameterDialog()" mat-icon-button>
            <mat-icon>add</mat-icon>
          </button>
        </mat-card-title>
        <mat-card-content>
          <ng-container *ngFor="let group of inputParametersFormArray().controls; let i = index">
            <div [formGroup]="group" class="flex-form repeated-form">
              <button mat-icon-button (click)="removeInputParameter(i)">
                <mat-icon inline="true">close</mat-icon>
              </button>
              <div [formGroup]="group.controls.settings" class="flex-form">
                <!--                  TODO: Make this a select box instead of input-->
                <glxy-form-field required>
                  <glxy-label>id</glxy-label>
                  <input matInput formControlName="id" required />
                  <glxy-error *ngIf="group.controls.settings.controls.id.hasError('required')">Required</glxy-error>
                </glxy-form-field>
                <glxy-form-field required>
                  <glxy-label>name</glxy-label>
                  <input matInput formControlName="name" required />
                  <glxy-error *ngIf="group.controls.settings.controls.name.hasError('required')">Required</glxy-error>
                </glxy-form-field>
                <div class="info">
                  <glxy-form-field required>
                    <glxy-label>Data Type</glxy-label>
                    <mat-select formControlName="dataType" required>
                      <mat-option *ngFor="let dataType of dataTypes" [value]="dataType">
                        {{ dataTypeLabels[dataType] }}
                      </mat-option>
                    </mat-select>
                    <glxy-error *ngIf="group.controls.settings.controls.dataType.hasError('invalid')">
                      Required or invalid. Set to Unknown if you are not sure
                    </glxy-error>
                  </glxy-form-field>
                  <mat-icon
                    glxyTooltip="The data type is used for things like comparisons in filters as well as (eventually) validation for parameter passing. The current types are primatives and most things aren't supported. IDs, while technically strings should not be considered strings in this way because we want to label them with what that ID actually means. E.G. account_group_id would be of type AccountGroupIdentifier and not string. If you are unsure select Unknown so it doesn't show up in if/else comparisons."
                    tooltipTitle="Data Type"
                    [highContrast]="false"
                    class="info-icon"
                    [inline]="true"
                  >
                    info_outline
                  </mat-icon>
                </div>
                <glxy-form-field>
                  <mat-checkbox formControlName="repeated">
                    Repeated
                    <extended>Is this a list of things?</extended>
                  </mat-checkbox>
                </glxy-form-field>
              </div>
              <div class="input-param-path">
                <glxy-form-field required>
                  <glxy-label>Path Key</glxy-label>
                  <input matInput formControlName="pathKey" required />
                  <glxy-hint>This is a key of your cloud function's request body. E.G.: partner_id.$</glxy-hint>
                  <glxy-error *ngIf="group.controls.pathKey.hasError('required')">Required</glxy-error>
                </glxy-form-field>
                <glxy-form-field required>
                  <glxy-label>Path Value</glxy-label>
                  <input matInput formControlName="pathValue" required />
                  <glxy-hint>
                    This where it'll get the data from that will be used to populate the key. E.G.:
                    {{ '{' }}.partner_id{{ '}' }}
                  </glxy-hint>
                  <glxy-error *ngIf="group.controls.pathValue.hasError('required')">Required</glxy-error>
                </glxy-form-field>
              </div>
              <mat-checkbox formControlName="required">Required</mat-checkbox>
              <glxy-form-field required>
                <glxy-label>Validation Type</glxy-label>
                <mat-select formControlName="validationType" required>
                  <mat-option
                    *ngFor="let validationType of validationTypes"
                    [value]="validationType.name"
                    [matTooltip]="validationType.description"
                  >
                    {{ validationTypeLabels[validationType.name] }}
                  </mat-option>
                </mat-select>
                <glxy-error *ngIf="group.controls.validationType.hasError('invalid')">Required or invalid</glxy-error>
              </glxy-form-field>
            </div>
          </ng-container>
          <button mat-stroked-button (click)="addInputParameter()">Add Input Parameter</button>
          <button mat-stroked-button (click)="testInputParameters()">Test Input Parameter</button>
        </mat-card-content>
      </mat-card>
      <mat-card appearance="outlined">
        <mat-card-title>
          Output Parameters
          <mat-icon
            glxyTooltip="This is what your resource returns inside a json object. By specifying what's returned we can use it in other steps of the automation"
            tooltipTitle="Output parameters."
            [highContrast]="false"
            class="info-icon"
            [inline]="true"
          >
            info_outline
          </mat-icon>
        </mat-card-title>
        <mat-card-content>
          <ng-container *ngFor="let group of outputParametersFormArray().controls; let i = index">
            <div [formGroup]="group" class="flex-form repeated-form">
              <button mat-icon-button (click)="removeOutputParameter(i)">
                <mat-icon inline="true">close</mat-icon>
              </button>
              <div [formGroup]="group.controls.settings" class="flex-form">
                <!--                  TODO: Make this a select box instead of input-->
                <glxy-form-field required>
                  <glxy-label>id</glxy-label>
                  <input matInput formControlName="id" required />
                  <glxy-error *ngIf="group.controls.settings.controls.id.hasError('required')">Required</glxy-error>
                </glxy-form-field>
                <glxy-form-field required>
                  <glxy-label>name</glxy-label>
                  <input matInput formControlName="name" required />
                  <glxy-error *ngIf="group.controls.settings.controls.name.hasError('required')">Required</glxy-error>
                </glxy-form-field>
                <div class="info">
                  <glxy-form-field required>
                    <glxy-label>Data Type</glxy-label>
                    <mat-select formControlName="dataType" required>
                      <mat-option *ngFor="let dataType of dataTypes" [value]="dataType">
                        {{ dataTypeLabels[dataType] }}
                      </mat-option>
                    </mat-select>
                    <glxy-error *ngIf="group.controls.settings.controls.dataType.hasError('invalid')">
                      Required or invalid. Set to Unknown if you are not sure
                    </glxy-error>
                  </glxy-form-field>
                  <mat-icon
                    glxyTooltip="The data type is used for things like comparisons in filters as well as (eventually) validation for parameter passing. The current types are primatives and most things aren't supported. IDs, while technically strings should not be considered strings in this way because we want to label them with what that ID actually means. E.G. account_group_id would be of type AccountGroupIdentifier and not string. If you are unsure select Unknown so it doesn't show up in if/else comparisons."
                    tooltipTitle="Data Type"
                    [highContrast]="false"
                    class="info-icon"
                    [inline]="true"
                  >
                    info_outline
                  </mat-icon>
                </div>
                <glxy-form-field>
                  <mat-checkbox formControlName="repeated">
                    Repeated
                    <extended>Is this a list of things?</extended>
                  </mat-checkbox>
                </glxy-form-field>
              </div>
              <div class="input-param-path">
                <glxy-form-field required>
                  <glxy-label>Path Key</glxy-label>
                  <input matInput formControlName="pathKey" required />
                  <glxy-hint>This is the destination the data will be added to stream at. E.G.: partner_id.$</glxy-hint>
                  <glxy-error *ngIf="group.controls.pathKey.hasError('required')">Required</glxy-error>
                </glxy-form-field>
                <glxy-form-field required>
                  <glxy-label>Path Value</glxy-label>
                  <input matInput formControlName="pathValue" required />
                  <glxy-hint>
                    This is the source the data is coming from (output of the resource) that will be used to populate
                    the key. E.G.:
                    {{ '{' }}.partner_id{{ '}' }}
                  </glxy-hint>
                  <glxy-error *ngIf="group.controls.pathValue.hasError('required')">Required</glxy-error>
                </glxy-form-field>
              </div>
              <div class="info" *ngIf="form.controls.taskType.value === TRIGGER">
                <mat-checkbox formControlName="isEntity">
                  Does this output define the type of entities the trigger can work with?
                </mat-checkbox>
              </div>
              <mat-checkbox formControlName="optional">Optional</mat-checkbox>
            </div>
          </ng-container>
          <button mat-stroked-button (click)="addOutputParameter()">Add Output Parameter</button>
        </mat-card-content>
      </mat-card>
    </div>
  </form>
  <div style="float: right">
    <button mat-button (click)="cancel()">Cancel</button>
    <button mat-raised-button color="primary" (click)="save()" [disabled]="saveDisabled">Save</button>
  </div>
</div>
