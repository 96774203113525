<glxy-page>
  <glxy-page-toolbar>
    <glxy-page-nav>
      <glxy-page-nav-button
        previousPageTitle="Task Definitions"
        [previousPageUrl]="'/automations/task-definitions/list'"
      />
    </glxy-page-nav>

    <glxy-page-title>Create Task Definition</glxy-page-title>
    <glxy-page-actions>
      <button mat-stroked-button (click)="import()">Import</button>
    </glxy-page-actions>
  </glxy-page-toolbar>
  <glxy-page-wrapper maxWidth="1160">
    <app-task-definition-form
      *ngIf="sourceEventTriggerDefinition$ | async as sourceEventTriggerDefinition"
      [taskType]="(taskType$ | async) || ''"
      [saveDisabled]="saveDisabled"
      #taskDefinitionForm
      (cancelClicked)="cancelClicked($event)"
      (saveClicked)="saveClicked($event)"
      [sourceEventTriggerDefinition]="sourceEventTriggerDefinition"
    />
  </glxy-page-wrapper>
</glxy-page>
