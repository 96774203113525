<glxy-page>
  <glxy-page-toolbar>
    <glxy-page-nav />
    <glxy-page-title>List Task Definitions</glxy-page-title>
    <glxy-page-actions>
      <button
        mat-stroked-button
        [routerLink]="['/automations/task-definitions/create']"
        [queryParams]="{ import: true }"
      >
        Import
      </button>
      <button mat-stroked-button color="primary" [matMenuTriggerFor]="globalActionsMenu">
        Create
        <mat-icon iconPositionEnd>arrow_drop_down</mat-icon>
      </button>
      <mat-menu #globalActionsMenu="matMenu">
        <button mat-menu-item (click)="createTaskDefinition(TaskType.TASK_TYPE_TRIGGER)">Trigger</button>
        <button mat-menu-item (click)="createTaskDefinition(TaskType.TASK_TYPE_ACTION)">Action</button>
        <button mat-menu-item (click)="createTaskDefinition(TaskType.TASK_TYPE_FILTER)">Filter</button>
      </mat-menu>
    </glxy-page-actions>
  </glxy-page-toolbar>
  <glxy-page-wrapper maxWidth="1280">
    <va-filtered-mat-table #filteredMatTableComponent [tableDataService]="dataService" />
  </glxy-page-wrapper>
</glxy-page>
