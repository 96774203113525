import { Injectable } from '@angular/core';
import {
  AutomataDevelopmentService,
  EventTriggerDefinitionInterface,
  ListEventTriggerDefinitionRequestFiltersInterface,
} from '@vendasta/automata';
import { LoadRequest, TableDataService } from '@vendasta/va-filter2-table';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, shareReplay, take } from 'rxjs/operators';
import { Filters } from './event-trigger-definitions-filters.service';

export interface EventTriggerDefinitionRow {
  eventTriggerDefinition: EventTriggerDefinitionInterface;
  rowNumber: number;
}

function convertFilters(filters: Filters): ListEventTriggerDefinitionRequestFiltersInterface {
  const f: ListEventTriggerDefinitionRequestFiltersInterface = {};

  if (filters.searchTerm) {
    f.name = filters.searchTerm;
  }

  return f;
}

@Injectable()
export class EventTriggerDefinitionsTableService implements TableDataService<EventTriggerDefinitionRow> {
  private readonly totalResults$$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public readonly totalResults$: Observable<number> = this.totalResults$$.asObservable();
  private cursors: { [id: number]: string } = { 0: '' };

  constructor(private readonly automataDevelopmentService: AutomataDevelopmentService) {}

  initialize(): void {
    // nothing to initialize currently
  }

  load(request: LoadRequest): Observable<EventTriggerDefinitionRow[]> {
    if (request.pageIndex === 0) {
      this.cursors = { 0: '' };
    }
    const nextCursor = this.cursors[request.pageIndex] || '';
    const filters = convertFilters(request.filters);
    return this.listEventTriggerDefinitions(nextCursor, filters, request.pageSize, request.pageIndex);
  }

  listEventTriggerDefinitions(
    nextCursor: string,
    filters: ListEventTriggerDefinitionRequestFiltersInterface,
    pageSize: number,
    pageIndex: number,
  ): Observable<EventTriggerDefinitionRow[]> {
    const listResponse$ = this.automataDevelopmentService
      .listEventTriggerDefinitions(filters, nextCursor, pageSize)
      .pipe(take(1), shareReplay(1));

    listResponse$.pipe(take(1)).subscribe((resp) => {
      this.cursors[pageIndex + 1] = resp.nextCursor;
      this.totalResults$$.next(resp.totalResults);
    });
    let current = pageSize * pageIndex;
    return listResponse$.pipe(
      map((listResponse) => {
        return (listResponse?.results || []).map((t) => {
          current++;
          return {
            eventTriggerDefinition: t,
            rowNumber: current,
          };
        });
      }),
    );
  }
}
