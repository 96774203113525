<glxy-page>
  <glxy-page-toolbar>
    <glxy-page-nav>
      <glxy-page-nav-button
        previousPageTitle="Event Trigger Definitions"
        [previousPageUrl]="'/automations/event-trigger-definitions/list'"
      />
    </glxy-page-nav>

    <glxy-page-title>Create Event Trigger Definition</glxy-page-title>
    <glxy-page-actions>
      <button mat-stroked-button (click)="import()">Import</button>
    </glxy-page-actions>
  </glxy-page-toolbar>
  <glxy-page-wrapper maxWidth="1160">
    <app-event-trigger-definition-form
      [saveDisabled]="saveDisabled"
      #eventTriggerDefinitionForm
      (cancelClicked)="cancelClicked($event)"
      (saveClicked)="saveClicked($event)"
    />
  </glxy-page-wrapper>
</glxy-page>
