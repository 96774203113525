import { TaskType } from '@vendasta/automata';

export function taskTypeToString(taskType: TaskType): string {
  switch (taskType) {
    case TaskType.TASK_TYPE_ACTION:
      return 'action';
    case TaskType.TASK_TYPE_TRIGGER:
      return 'trigger';
    case TaskType.TASK_TYPE_FILTER:
      return 'filter';
    case TaskType.TASK_TYPE_BUILDING_BLOCKS:
      return 'buildingblocks';
    default:
      return '';
  }
}

export function stringToTaskType(taskType: string): TaskType {
  switch (taskType) {
    case 'action':
      return TaskType.TASK_TYPE_ACTION;
    case 'trigger':
      return TaskType.TASK_TYPE_TRIGGER;
    case 'filter':
      return TaskType.TASK_TYPE_FILTER;
    case 'buildingblocks':
      return TaskType.TASK_TYPE_BUILDING_BLOCKS;
    default:
      return TaskType.TASK_TYPE_UNSPECIFIED;
  }
}

export function buildLoggingUrl(url: string): string {
  const functionName = url.replace('https://us-central1-repcore-prod.cloudfunctions.net/', '');
  return `https://console.cloud.google.com/logs/query;query=resource.type%20%3D%20%22cloud_function%22%0Aresource.labels.function_name%20%3D%20%22${functionName}%22%0Alog_name%3D%22projects%2Frepcore-prod%2Flogs%2Frequest%22?project=repcore-prod`;
}
