import { GenericTableLabels } from '@vendasta/va-filter2-table';
import { Context, DataType, PublishedState, TaskType, ValidationType } from '@vendasta/automata';

export const AUTOMATION_TABLE_LABELS: GenericTableLabels = {
  noResults: 'No results',
  cancel: 'Cancel',
  none: 'None',
  update: 'Update',
  sortBy: 'Sort by',
  thenSortBy: 'Then sort by',
  addAnotherSortColumn: 'Add another sort column',
  chooseColumnToSortBy: 'Choose column to sort by',
  columnSorting: {
    date: {
      ascending: 'latest',
      descending: 'earliest',
    },
    number: {
      ascending: 'ascending',
      descending: 'descending',
    },
    string: {
      ascending: 'a to z',
      descending: 'z to a',
    },
    default: {
      ascending: 'ascending',
      descending: 'descending',
    },
  },
  columnSelector: {
    buttonText: 'Columns',
  },
  export: {
    buttonText: '',
    buttonTooltipBeforeCount: '',
    buttonTooltipAfterCount: '',
  },
};

export const taskTypeLabels = {
  [TaskType.TASK_TYPE_ACTION]: 'Action',
  [TaskType.TASK_TYPE_TRIGGER]: 'Trigger',
  [TaskType.TASK_TYPE_BUILDING_BLOCKS]: 'Building Blocks',
  [TaskType.TASK_TYPE_FILTER]: 'Filter',
};

export const taskTypes = [
  TaskType.TASK_TYPE_ACTION,
  TaskType.TASK_TYPE_TRIGGER,
  TaskType.TASK_TYPE_BUILDING_BLOCKS,
  TaskType.TASK_TYPE_FILTER,
];

export const publishedStateLabels = {
  [PublishedState.PUBLISHED_STATE_PUBLISHED]: 'Published',
  [PublishedState.PUBLISHED_STATE_DRAFT]: 'Draft',
};

export const publishedStates = [PublishedState.PUBLISHED_STATE_PUBLISHED, PublishedState.PUBLISHED_STATE_DRAFT];

export const validationTypeLabels = {
  [ValidationType.VALIDATION_TYPE_STREAM]: 'Stream',
  [ValidationType.VALIDATION_TYPE_SYSTEM]: 'System',
  [ValidationType.VALIDATION_TYPE_USER_PROVIDED]: 'User Provided',
};

export const validationTypes = [
  {
    name: ValidationType.VALIDATION_TYPE_STREAM,
    description: `Run time data that is available for other steps to use. This is a combination of the output of the trigger and contributed to by the output of pervious steps.
      E.g. The activate products step returns the product that was activated so that is available in the "stream" for subsequent steps`,
  },
  {
    name: ValidationType.VALIDATION_TYPE_SYSTEM,
    description:
      'This is data that the system provides for every automation. This validation type essentially means "don\'t worry about validating this because the data is guaranteed to exist"',
  },
  {
    name: ValidationType.VALIDATION_TYPE_USER_PROVIDED,
    description:
      'This is data that the user inputs via the automation side panel. These data fields are ignored when validating what steps you can add but are included when validating the automation when the user saves',
  },
];

export const supportedContexts = [Context.AUTOMATION_CONTEXT_PARTNER, Context.AUTOMATION_CONTEXT_SMB];
export const supportedContextsLabels = {
  [Context.AUTOMATION_CONTEXT_PARTNER]: 'Partner',
  [Context.AUTOMATION_CONTEXT_SMB]: 'SMB',
};

const dataTypeLabelsMap = new Map([
  [DataType.DATA_TYPE_UNKNOWN, 'Unknown'],
  [DataType.DATA_TYPE_STRING, 'String'],
  [DataType.DATA_TYPE_BOOLEAN, 'Boolean'],
  [DataType.DATA_TYPE_ORDER_ACTION_TYPE, 'Order action'],
  [DataType.DATA_TYPE_ORDER_DECLINED_REASON_IDS, 'Order declined reason IDs'],
  [DataType.DATA_TYPE_ACCOUNT_GROUP_ID, 'Account'],
  [DataType.DATA_TYPE_ORDER_ID, 'Order'],
  [DataType.DATA_TYPE_SMB_USER_ID, 'SMB User'],
  [DataType.DATA_TYPE_FULFILLMENT_PROJECT_ID, 'Fulfillment Project'],
  [DataType.DATA_TYPE_EMAIL_ID, 'Email'],
  [DataType.DATA_TYPE_EMAIL_EVENT, 'Email Event'],
  [DataType.DATA_TYPE_OPPORTUNITY_ID, 'Opportunity'],
  [DataType.DATA_TYPE_COMPANY_ID, 'Company'],
  [DataType.DATA_TYPE_CONTACT_ID, 'Contact'],
  [DataType.DATA_TYPE_JSON, 'JSON'],
  [DataType.DATA_TYPE_PRODUCT, 'Product'],
  [DataType.DATA_TYPE_TASK, 'CRM Task'],
  [DataType.DATA_TYPE_CRM_NOTE, 'CRM Note'],
  [DataType.DATA_TYPE_CRM_EMAIL, 'CRM Email'],
  [DataType.DATA_TYPE_CRM_CALL, 'CRM Call'],
  [DataType.DATA_TYPE_CRM_MEETING, 'CRM Meeting'],
  [DataType.DATA_TYPE_CRM_SMS, 'CRM Sms'],
  [DataType.DATA_TYPE_CRM_INBOX, 'CRM Inbox'],
  [DataType.DATA_TYPE_CRM_LINKEDIN, 'CRM Linkedin'],
  [DataType.DATA_TYPE_INTEGRATION_CONNECTION, 'Integration Connection'],
  [DataType.DATA_TYPE_NUMBER, 'Number'],
  [DataType.DATA_TYPE_CURRENCY, 'Currency'],
  [DataType.DATA_TYPE_DATETIME, 'Datetime'],
]);

export const dataTypeLabels = Object.fromEntries(dataTypeLabelsMap.entries());

export const dataTypes: DataType[] = [...dataTypeLabelsMap.keys()];
