import { Component, OnInit } from '@angular/core';
import { TaskDefinitionsTableCustomComponent } from './common-cell.component';
import { buildLoggingUrl } from '../../../conversion';

@Component({
  template: `
    <a
      [href]="url"
      target="_blank"
      glxyTooltip="If there are no logs at the end of this link, update the cloud function to have the latest version of resource_helper"
      *ngIf="!!display"
    >
      {{ display }}
    </a>
  `,
})
export class ResourceCellComponent extends TaskDefinitionsTableCustomComponent implements OnInit {
  display: string;
  url: string;

  constructor() {
    super();
  }

  ngOnInit(): void {
    if (this.element?.taskDefinition?.resource) {
      this.url = buildLoggingUrl(this.element.taskDefinition.resource.url);
      this.display = this.element.taskDefinition.resource.url;
    }
  }
}
