import { ColumnType, Pinned, TableDefinition } from '@vendasta/va-filter2-table';
import { TaskDefintionsFiltersService } from './task-defintions-filters.service';
import { TaskTypeCellComponent } from './cells/task-type-cell.component';
import { PublishedStateCellComponent } from './cells/published-state-cell.component';
import { InputParamsCellComponent } from './cells/input-params-cell.component';
import { OutputParamsCellComponent } from './cells/output-params-cell.component';
import { BooleanCellComponent } from '../../../common/boolean-cell.component';
import { DateCellComponent } from '../../../common/date-cell.component';
import { AUTOMATION_TABLE_LABELS } from '../../../common/constants';
import { NameCellComponent } from './cells/name-cell.component';
import { ResourceCellComponent } from './cells/resource-cell.component';
import { SupportedContextsCellComponent } from './cells/supported-contexts-cell.component';

export const tableDefinitionFactory = (filterService: TaskDefintionsFiltersService) => {
  const tableDefintion = TASK_DEFINITIONS_TABLE;
  tableDefintion.filters = filterService.filters;
  return tableDefintion;
};

const TASK_DEFINITIONS_TABLE: TableDefinition = {
  id: 'task-definitions',
  labels: AUTOMATION_TABLE_LABELS,
  options: {
    selectableRows: false,
    csvExportEnabled: false,
    tableTitle: 'Task definitions',
  },
  columns: [
    {
      id: 'row',
      displayName: 'Row',
      pinned: Pinned.PINNED_LEFT,
      type: ColumnType.COLUMN_TYPE_STRING,
      field: 'rowNumber',
    },
    {
      id: 'name',
      displayName: 'Name',
      cellComponent: NameCellComponent,
    },
    {
      id: 'resource',
      displayName: 'Resource',
      cellComponent: ResourceCellComponent,
    },
    {
      id: 'taskType',
      displayName: 'Task type',
      field: 'taskDefinition.taskType',
      cellComponent: TaskTypeCellComponent,
    },
    {
      id: 'publishedState',
      displayName: 'Published state',
      field: 'taskDefinition.publishedState',
      cellComponent: PublishedStateCellComponent,
    },
    {
      id: 'inputParams',
      displayName: 'Input Params',
      cellComponent: InputParamsCellComponent,
    },
    {
      id: 'outputParams',
      displayName: 'Output Params',
      cellComponent: OutputParamsCellComponent,
    },
    {
      id: 'internal',
      displayName: 'Internal',
      field: 'taskDefinition.internal',
      cellComponent: BooleanCellComponent,
    },
    {
      id: 'availableInDefaultAutomations',
      displayName: 'Available In Default Automations',
      field: 'taskDefinition.availableInDefaultAutomations',
      cellComponent: BooleanCellComponent,
    },
    {
      id: 'id',
      displayName: 'ID',
      type: ColumnType.COLUMN_TYPE_STRING,
      field: 'taskDefinition.id',
    },
    {
      id: 'created',
      displayName: 'Created',
      field: 'taskDefinition.created',
      cellComponent: DateCellComponent,
    },
    {
      id: 'supportedContexts',
      displayName: 'Supported Contexts',
      cellComponent: SupportedContextsCellComponent,
    },
  ],
};
