import { Component, Inject, Input } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';
import JSONPath from 'jsonpath/jsonpath';
import { validateJson } from '../form-helpers';

const exampleData = {
  partner_id: 'ABC',
  market_id: 'default',
  account_group_id: 'AG-123',
};

export interface Data {
  toTest: Record<string, any>;
}

@Component({
  selector: 'app-json-path-tester-dialog',
  templateUrl: './json-path-tester-dialog.component.html',
  styleUrls: ['./json-path-tester-dialog.component.scss'],
})
export class JsonPathTesterDialogComponent {
  inputDataControl = new UntypedFormControl(JSON.stringify(exampleData, null, 4), [Validators.required, validateJson]);
  outputDataControl = new UntypedFormControl({ value: '', disabled: true });

  @Input()
  toTest: Record<string, any> = {};

  constructor(@Inject(MAT_DIALOG_DATA) readonly data: Data, private readonly alertService: SnackbarService) {
    this.toTest = data.toTest;
  }

  test(): void {
    const outputData = {};
    Object.entries(this.toTest).map((ent) => {
      const key = ent[0];
      const path = ent[1];
      if (key.endsWith('.$')) {
        //ending with .$ means we should execute the path in 'path' instead of treating path as a constant value
        const strippedKey = key.replace('.$', '');
        outputData[strippedKey] = this.executeJsonPath(this.inputDataControl.value, path);
      } else {
        outputData[key] = path;
      }
    });
    this.outputDataControl.setValue(JSON.stringify(outputData, null, 4));
  }

  executeJsonPath(data: string, path: string): string {
    // Our paths are stored as {.partner_id} but this JSONPath library expect it in the form of $.partner_id, need to convert to that path
    if (!path.startsWith('{.') || !path.endsWith('}')) {
      this.alertService.errorSnack('malformed path, should be in the form of {.partner_id}');
      return '';
    }

    const pathWithStrippedCurlyBraces = path.substr(1, path.length - 2);
    const pathStartWithDollar = '$' + pathWithStrippedCurlyBraces;
    return JSONPath.value(JSON.parse(data), pathStartWithDollar) || '';
  }
}
