import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LexiconModule } from '@galaxy/lexicon';
import { GalaxyFormFieldModule } from '@vendasta/galaxy/form-field';
import { GalaxyWrapModule } from '@vendasta/galaxy/galaxy-wrap';
import { GalaxyPageModule } from '@vendasta/galaxy/page';
import { GalaxySnackbarModule } from '@vendasta/galaxy/snackbar-service';
import { GalaxyTooltipModule } from '@vendasta/galaxy/tooltip';
import { Filter2TableModule } from '@vendasta/va-filter2-table';
import { InViewportModule } from 'ng-in-viewport';
import { AutomationsRoutingModule } from './automations.routing.module';
import { BooleanCellComponent } from './common/boolean-cell.component';
import { DateCellComponent } from './common/date-cell.component';
import { ImportFromJsonDialogComponent } from './common/import-from-json-dialog/import-from-json-dialog.component';
import { JsonPathTesterDialogComponent } from './common/json-path-tester-dialog/json-path-tester-dialog.component';
import { CreateEventTriggerDefinitionPageComponent } from './event-trigger-definitions/create-event-trigger-definition-page/create-event-trigger-definition-page.component';
import { EditEventTriggerDefinitionPageComponent } from './event-trigger-definitions/edit-event-trigger-definition-page/edit-event-trigger-definition-page.component';
import { EventTriggerDefinitionFormComponent } from './event-trigger-definitions/event-trigger-definition-form/event-trigger-definition-form.component';
import { EventTriggerDefinitionsListPageComponent } from './event-trigger-definitions/event-trigger-definitions-list-page/event-trigger-definitions-list-page.component';
import { NameCellComponent as EventTriggerDefinitionNameCellComponent } from './event-trigger-definitions/event-trigger-definitions-list-page/event-trigger-defintions-table/cells/name-cell.component';
import { CreateTaskDefinitionPageComponent } from './task-definitions/create-task-definition-page/create-task-definition-page.component';
import { EventTriggerDefinitionSelectorDialogComponent } from './task-definitions/create-task-definition-page/event-trigger-definition-selector-dialog/event-trigger-definition-selector-dialog.component';
import { EditTaskDefinitionPageComponent } from './task-definitions/edit-task-definition-page/edit-task-definition-page.component';
import { AddInputParameterDialogComponent } from './task-definitions/task-definition-form/add-input-parameter-dialog/add-input-parameter-dialog.component';
import { TaskDefinitionFormComponent } from './task-definitions/task-definition-form/task-definition-form.component';
import { TaskDefinitionsListPageComponent } from './task-definitions/task-definitions-list-page/task-definitions-list-page.component';
import { InputParamsCellComponent } from './task-definitions/task-definitions-list-page/task-defintions-table/cells/input-params-cell.component';
import { NameCellComponent as TaskDefinitionNameCellComponent } from './task-definitions/task-definitions-list-page/task-defintions-table/cells/name-cell.component';
import { OutputParamsCellComponent } from './task-definitions/task-definitions-list-page/task-defintions-table/cells/output-params-cell.component';
import { PublishedStateCellComponent } from './task-definitions/task-definitions-list-page/task-defintions-table/cells/published-state-cell.component';
import { ResourceCellComponent } from './task-definitions/task-definitions-list-page/task-defintions-table/cells/resource-cell.component';
import { SupportedContextsCellComponent } from './task-definitions/task-definitions-list-page/task-defintions-table/cells/supported-contexts-cell.component';
import { TaskTypeCellComponent } from './task-definitions/task-definitions-list-page/task-defintions-table/cells/task-type-cell.component';
import { RuleInputComponent } from './task-definitions/rule-input/rule-input.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { AndOrInputComponent } from './task-definitions/and-or-input/and-or-input.component';
import { GalaxyAlertModule } from '@vendasta/galaxy/alert';

@NgModule({
  declarations: [
    TaskDefinitionsListPageComponent,
    DateCellComponent,
    BooleanCellComponent,
    PublishedStateCellComponent,
    ResourceCellComponent,
    TaskTypeCellComponent,
    OutputParamsCellComponent,
    InputParamsCellComponent,
    EventTriggerDefinitionsListPageComponent,
    EditTaskDefinitionPageComponent,
    TaskDefinitionNameCellComponent,
    SupportedContextsCellComponent,
    TaskDefinitionFormComponent,
    CreateTaskDefinitionPageComponent,
    AddInputParameterDialogComponent,
    EditEventTriggerDefinitionPageComponent,
    EventTriggerDefinitionNameCellComponent,
    EventTriggerDefinitionFormComponent,
    CreateEventTriggerDefinitionPageComponent,
    EventTriggerDefinitionSelectorDialogComponent,
    JsonPathTesterDialogComponent,
    ImportFromJsonDialogComponent,
  ],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    MatListModule,
    MatToolbarModule,
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    AutomationsRoutingModule,
    MatTooltipModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatRadioModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatTabsModule,
    GalaxySnackbarModule,
    MatCheckboxModule,
    GalaxyPageModule,
    LexiconModule.forChild(),
    Filter2TableModule,
    MatCardModule,
    GalaxyWrapModule,
    MatDividerModule,
    MatDialogModule,
    MatMenuModule,
    InViewportModule,
    GalaxyTooltipModule,
    GalaxyFormFieldModule,
    MatSlideToggleModule,
    RuleInputComponent,
    AndOrInputComponent,
    MatAutocompleteModule,
    GalaxyAlertModule,
  ],
  providers: [],
})
export class AutomationsModule {}
