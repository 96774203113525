import { ColumnType, Pinned, TableDefinition } from '@vendasta/va-filter2-table';
import { EventTriggerDefinitionsFiltersService } from './event-trigger-definitions-filters.service';
import { AUTOMATION_TABLE_LABELS } from '../../../common/constants';
import { DateCellComponent } from '../../../common/date-cell.component';
import { NameCellComponent } from './cells/name-cell.component';

export const tableDefinitionFactory = (filterService: EventTriggerDefinitionsFiltersService) => {
  const tableDefintion = TASK_DEFINITIONS_TABLE;
  tableDefintion.filters = filterService.filters;
  return tableDefintion;
};

const TASK_DEFINITIONS_TABLE: TableDefinition = {
  id: 'task-definitions',
  labels: AUTOMATION_TABLE_LABELS,
  options: {
    selectableRows: false,
    csvExportEnabled: false,
    tableTitle: 'Event trigger definitions',
  },
  columns: [
    {
      id: 'row',
      displayName: 'Row',
      pinned: Pinned.PINNED_LEFT,
      type: ColumnType.COLUMN_TYPE_STRING,
      field: 'rowNumber',
    },
    {
      id: 'name',
      displayName: 'Name',
      type: ColumnType.COLUMN_TYPE_STRING,
      field: 'eventTriggerDefinition.name',
      cellComponent: NameCellComponent,
    },
    {
      id: 'eventTypeId',
      displayName: 'Event Type ID',
      type: ColumnType.COLUMN_TYPE_STRING,
      field: 'eventTriggerDefinition.eventTypeId',
    },
    {
      id: 'triggerContextIdFormat',
      displayName: 'Trigger Context ID',
      type: ColumnType.COLUMN_TYPE_STRING,
      field: 'eventTriggerDefinition.triggerContextIdFormat',
    },
    {
      id: 'created',
      displayName: 'Created',
      field: 'eventTriggerDefinition.created',
      cellComponent: DateCellComponent,
    },
    {
      id: 'deleted',
      displayName: 'Deleted',
      field: 'eventTriggerDefinition.deleted',
      cellComponent: DateCellComponent,
    },
  ],
};
