import { Component } from '@angular/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { TABLE_DEFINITION, VaFilteredMatTableService, VaTableSortService } from '@vendasta/va-filter2-table';
import { EventTriggerDefinitionsFiltersService } from './event-trigger-defintions-table/event-trigger-definitions-filters.service';
import { EventTriggerDefinitionsTableService } from './event-trigger-defintions-table/event-trigger-definitions-table.service';
import { tableDefinitionFactory } from './event-trigger-defintions-table/table-model';

@Component({
  selector: 'app-event-trigger-definitions-list-page',
  templateUrl: './event-trigger-definitions-list-page.component.html',
  styleUrls: ['./event-trigger-definitions-list-page.component.scss'],
  providers: [
    EventTriggerDefinitionsTableService,
    EventTriggerDefinitionsFiltersService,
    {
      provide: TABLE_DEFINITION,
      useFactory: tableDefinitionFactory,
      deps: [EventTriggerDefinitionsFiltersService],
    },
    VaFilteredMatTableService,
    VaTableSortService,
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {} },
  ],
})
export class EventTriggerDefinitionsListPageComponent {
  constructor(readonly dataService: EventTriggerDefinitionsTableService) {}
}
