import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AutomataDevelopmentService, EventTriggerDefinitionInterface } from '@vendasta/automata';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map, scan, switchMap, tap } from 'rxjs/operators';
import { PagedResponse } from '@vendasta/automata/lib/shared';

@Component({
  selector: 'app-event-trigger-definition-selector-dialog',
  templateUrl: './event-trigger-definition-selector-dialog.component.html',
  styleUrls: ['./event-trigger-definition-selector-dialog.component.scss'],
})
export class EventTriggerDefinitionSelectorDialogComponent implements OnInit {
  eventTriggerDefinitions$: Observable<EventTriggerDefinitionInterface[]>;
  nextCursorStorage: string;
  nextCursorTrigger$$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  private hasMore$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  hasMore$: Observable<boolean> = this.hasMore$$.asObservable();

  loading = false;

  constructor(
    public dialogRef: MatDialogRef<EventTriggerDefinitionSelectorDialogComponent, EventTriggerDefinitionInterface>,
    private readonly automataDevelopmentService: AutomataDevelopmentService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.eventTriggerDefinitions$ = this.nextCursorTrigger$$.pipe(
      tap(() => {
        this.loading = true;
        this.cdr.detectChanges();
      }),
      switchMap((cursor) => this.automataDevelopmentService.listEventTriggerDefinitions(null, cursor, 25)),
      tap((resp: PagedResponse<EventTriggerDefinitionInterface>) => this.hasMore$$.next(!!resp?.hasMore)),
      tap((resp: PagedResponse<EventTriggerDefinitionInterface>) => (this.nextCursorStorage = resp?.nextCursor || '')),
      map((resp: PagedResponse<EventTriggerDefinitionInterface>) => resp?.results || []),
      scan((acc, curr) => {
        return [...acc, ...curr];
      }, []),
      tap(() => {
        this.loading = false;
        this.cdr.detectChanges();
      }),
      catchError((err) => {
        console.error(err);
        return throwError(err);
      }),
    );
  }

  loadMore(inViewportEvent: any): void {
    if (inViewportEvent.visible) {
      this.nextCursorTrigger$$.next(this.nextCursorStorage);
    }
  }

  selectEventTriggerDefinition(e: EventTriggerDefinitionInterface): void {
    this.dialogRef.close(e);
  }
}
