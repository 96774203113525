import { Injectable } from '@angular/core';
import { Context, PublishedState, TaskType } from '@vendasta/automata';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';
import {
  CheckboxFilterControl,
  FilterGroup,
  FilterService,
  MultiSelectFilterControl,
  SearchFilterControl,
  SelectFilterControl,
} from '@vendasta/va-filter2';
import { of } from 'rxjs';
import {
  publishedStateLabels,
  supportedContexts,
  supportedContextsLabels,
  taskTypeLabels,
} from '../../../common/constants';

export interface Filters {
  taskType: TaskType;
  publishedState: PublishedState;
  internal: boolean;
  onlyDefault: boolean;
  searchTerm: string;
  supportedContexts: Context[];
}

@Injectable()
export class TaskDefintionsFiltersService {
  filters: FilterGroup = new FilterGroup('task-definitions');

  constructor(private readonly filterService: FilterService, private readonly snackbarService: SnackbarService) {
    const taskTypes: Map<string, TaskType> = new Map<string, TaskType>();
    taskTypes.set('Action', TaskType.TASK_TYPE_ACTION);
    taskTypes.set('Trigger', TaskType.TASK_TYPE_TRIGGER);
    taskTypes.set('Building Blocks', TaskType.TASK_TYPE_BUILDING_BLOCKS);
    taskTypes.set('Filter', TaskType.TASK_TYPE_FILTER);

    const publishedStates: Map<string, PublishedState> = new Map<string, PublishedState>();
    publishedStates.set('Published', PublishedState.PUBLISHED_STATE_PUBLISHED);
    publishedStates.set('Draft', PublishedState.PUBLISHED_STATE_DRAFT);

    const supportedContextsFilter: Map<string, Context> = new Map<string, Context>();
    supportedContexts.map((context) => supportedContextsFilter.set(supportedContextsLabels[context], context));

    this.filters.addToolbarSection(new SearchFilterControl('searchTerm', 'Search'));
    this.filters.addSection('Filters', [
      new SelectFilterControl('taskType', 'Task Type', of(taskTypes), false, {
        appliedValueMapper: (name, value) => ({ name, label: taskTypeLabels[value] }),
      }),
      new SelectFilterControl('publishedState', 'Published State', of(publishedStates), false, {
        appliedValueMapper: (name, value) => ({ name, label: publishedStateLabels[value] }),
      }),
      new MultiSelectFilterControl('supportedContexts', 'Supported Contexts', of(supportedContextsFilter), null, {
        appliedValueMapper: (name, value) => ({ name: name, value: value, label: supportedContextsLabels[value] }),
      }),
    ]);

    this.filters.addSection('Extra Stuff', [
      new CheckboxFilterControl('internal', 'Internal', false, {
        appliedValueMapper: (name) => ({ name, label: name }),
      }),
      new CheckboxFilterControl('onlyDefault', 'Available In Default', false, {
        appliedValueMapper: (name) => ({ name, label: name }),
      }),
    ]);
  }
}
