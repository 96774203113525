import { Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { EventTriggerDefinitionInterface, FlattenedConfigDataType } from '@vendasta/automata';
import { EventBrokerService, EventTypeInterface } from '@vendasta/event-broker';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';
import { BehaviorSubject, combineLatest, Observable, throwError } from 'rxjs';
import { catchError, map, scan, startWith, switchMap, take, tap } from 'rxjs/operators';
import { updateControlValidity } from '../../common/form-helpers';
import { ImportFromJsonDialogComponent } from '../../common/import-from-json-dialog/import-from-json-dialog.component';
import { JsonPathTesterDialogComponent } from '../../common/json-path-tester-dialog/json-path-tester-dialog.component';

@Component({
  selector: 'app-event-trigger-definition-form',
  templateUrl: './event-trigger-definition-form.component.html',
  styleUrls: ['./event-trigger-definition-form.component.scss'],
})
export class EventTriggerDefinitionFormComponent implements OnInit {
  @Input() eventTriggerDefinition: EventTriggerDefinitionInterface = {};
  @Input() saveDisabled: boolean;

  @Output() cancelClicked: EventEmitter<boolean> = new EventEmitter();
  @Output() saveClicked: EventEmitter<EventTriggerDefinitionInterface> = new EventEmitter();

  flattenedConfigDataType = FlattenedConfigDataType;

  eventTypes$: Observable<EventTypeInterface[]>;
  eventTypesNextCursorTrigger$$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  form: UntypedFormGroup = new UntypedFormGroup({
    eventTypeId: new UntypedFormControl('', Validators.required), // Only used for create
    name: new UntypedFormControl('', Validators.required),
    parametersPaths: new UntypedFormArray([], Validators.required),
    triggerContextIdFormat: new UntypedFormControl('', Validators.required),
    flattenFieldToggle: new FormControl(false),
    flattenFieldDataType: new FormControl(0),
    flattenFieldSourcePath: new FormControl(''),
    flattenFieldDestinationPath: new FormControl(''),
  });

  filteredEventTypes$: Observable<EventTypeInterface[]>;

  private static getParametersPathFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      inStream: new UntypedFormControl('', Validators.required),
      fromEvent: new UntypedFormControl('', Validators.required),
      isFilterKey: new UntypedFormControl(''),
    });
  }

  private destroyRef = inject(DestroyRef);

  constructor(
    private readonly alertService: SnackbarService,
    private dialog: MatDialog,
    public eventBrokerService: EventBrokerService,
  ) {}

  parametersPathsFormArray(): UntypedFormArray {
    return this.form.controls.parametersPaths as UntypedFormArray;
  }

  ngOnInit(): void {
    if (this.eventTriggerDefinition?.eventTypeId) {
      this.form.controls.eventTypeId.disable();
    }
    this.populateFormFromEventTriggerDefinition();

    this.eventTypes$ = this.eventTypesNextCursorTrigger$$.pipe(
      switchMap((cursor) => this.eventBrokerService.listEventTypes(cursor, 50)),
      tap((resp) => (resp?.nextCursor ? this.eventTypesNextCursorTrigger$$.next(resp.nextCursor) : null)),
      map((resp) => resp?.eventTypes || []),
      scan((acc, curr) => {
        return [...acc, ...curr];
      }, []),
      catchError((err) => {
        console.error(err);
        return throwError(err);
      }),
    );

    this.filteredEventTypes$ = combineLatest([
      this.form.controls.eventTypeId.valueChanges.pipe(startWith('')),
      this.eventTypes$,
    ]).pipe(
      map(([value, eventTypes]) => {
        if (!value) {
          return eventTypes;
        }
        return eventTypes.filter((eventType) => eventType.name.toLocaleLowerCase().includes(value.toLowerCase()));
      }),
      map((eventTypes) => eventTypes.sort((a, b) => a.name.localeCompare(b.name))),
    );

    // Enforce required fields for the Flattened Config section
    this.form.controls.flattenFieldToggle.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value) => {
      if (value) {
        this.form.controls.flattenFieldDataType.setValidators([Validators.required]);
        this.form.controls.flattenFieldSourcePath.setValidators([Validators.required]);
        this.form.controls.flattenFieldDestinationPath.setValidators([Validators.required]);
      } else {
        this.form.controls.flattenFieldDataType.setValidators([]);
        this.form.controls.flattenFieldSourcePath.setValidators([]);
        this.form.controls.flattenFieldDestinationPath.setValidators([]);
      }
      this.form.controls.flattenFieldDataType.updateValueAndValidity();
      this.form.controls.flattenFieldSourcePath.updateValueAndValidity();
      this.form.controls.flattenFieldDestinationPath.updateValueAndValidity();
    });
    this.form.controls.flattenFieldDataType.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => {
        if (
          value === FlattenedConfigDataType.FLATTENED_CONFIG_DATA_TYPE_UNSET ||
          !this.form.controls.flattenFieldToggle.value
        ) {
          return;
        }
        if (value === FlattenedConfigDataType.FLATTENED_CONFIG_DATA_TYPE_STRUCT) {
          this.form.controls.flattenFieldDestinationPath.setValidators([]);
        } else {
          this.form.controls.flattenFieldDestinationPath.setValidators([Validators.required]);
        }
        this.form.controls.flattenFieldDestinationPath.updateValueAndValidity();
      });
  }

  populateFormFromEventTriggerDefinition(): void {
    this.form.controls.eventTypeId.setValue(this.eventTriggerDefinition?.eventTypeId);
    this.form.controls.name.setValue(this.eventTriggerDefinition?.name);
    this.form.controls.triggerContextIdFormat.setValue(this.eventTriggerDefinition?.triggerContextIdFormat);

    (this.form.controls.parametersPaths as UntypedFormArray).clear();
    const parametersPaths = JSON.parse(this.eventTriggerDefinition?.parametersPaths || '{}');
    Object.entries(parametersPaths || {}).map((pp) => {
      if (!pp[0] || !pp[1]) {
        this.alertService.errorSnack('something went terribly wrong parsing the parametersPaths');
      }
      const fg = EventTriggerDefinitionFormComponent.getParametersPathFormGroup();
      fg.controls.inStream.setValue(pp[0]);
      fg.controls.fromEvent.setValue(pp[1]);
      fg.controls.isFilterKey.setValue(
        this.inParameterFilterKeys(pp[0] as string, this.eventTriggerDefinition?.parameterFilterKeys),
      );
      (this.form.controls.parametersPaths as UntypedFormArray).push(fg);
    });
    if (this.eventTriggerDefinition.flattenedConfig) {
      this.form.controls.flattenFieldToggle.setValue(true);
      this.form.controls.flattenFieldDataType.setValue(this.eventTriggerDefinition.flattenedConfig.dataType);

      const flattenedConfigPath = JSON.parse(this.eventTriggerDefinition.flattenedConfig.sourcePath);
      this.form.controls.flattenFieldDestinationPath.setValue(Object.keys(flattenedConfigPath)[0]);
      this.form.controls.flattenFieldSourcePath.setValue(flattenedConfigPath[Object.keys(flattenedConfigPath)[0]]);
    }
  }

  inParameterFilterKeys(parameterPath: string, parameterFilterKeys: string[]): boolean {
    const strippedPath = parameterPath.replace('.$', '');

    return parameterFilterKeys?.indexOf(strippedPath) > -1;
  }

  addParametersPath(): void {
    (this.form.controls.parametersPaths as UntypedFormArray).push(
      EventTriggerDefinitionFormComponent.getParametersPathFormGroup(),
    );
  }

  removeParametersPath(index: number): void {
    (this.form.controls.parametersPaths as UntypedFormArray).removeAt(index);
  }

  cancel(): void {
    this.cancelClicked.emit(true);
  }

  save(): void {
    if (!this.form.valid) {
      updateControlValidity(this.form);
      this.alertService.errorSnack('You need to correctly fill out the form :/');
      return;
    }

    const parametersPathsObj = {};
    const parameterFilterKeys = [];
    (this.form.controls.parametersPaths as UntypedFormArray).controls.map((controls) => {
      const group = controls as UntypedFormGroup;
      const inStream = group.controls.inStream.value;
      const fromEvent = group.controls.fromEvent.value;
      if (group.controls.isFilterKey.value) {
        parameterFilterKeys.push(inStream.replace('.$', ''));
      }
      parametersPathsObj[inStream] = fromEvent;
    });
    const parametersPath = JSON.stringify(parametersPathsObj);

    let flattenedFieldConfig = null;
    if (this.form.controls.flattenFieldToggle.value) {
      const flattenedFieldPath = {};
      flattenedFieldPath[this.form.controls.flattenFieldDestinationPath.value || ''] =
        this.form.controls.flattenFieldSourcePath.value;
      flattenedFieldConfig = {
        sourcePath: JSON.stringify(flattenedFieldPath),
        dataType: this.form.controls.flattenFieldDataType.value,
      };
    }

    const newEventTriggerDefinition: EventTriggerDefinitionInterface = {
      eventTypeId: this.eventTriggerDefinition?.eventTypeId
        ? this.eventTriggerDefinition?.eventTypeId
        : this.form.controls.eventTypeId.value,
      name: this.form.controls.name.value,
      parametersPaths: parametersPath,
      triggerContextIdFormat: this.form.controls.triggerContextIdFormat.value,
      parameterFilterKeys: parameterFilterKeys,
      flattenedConfig: flattenedFieldConfig,
    };
    this.saveClicked.emit(newEventTriggerDefinition);
  }

  testParametersPaths(): void {
    const toTest = this.parametersPathsFormArray().controls.reduce((prev, curr) => {
      const group = curr as UntypedFormGroup;
      const inStream = group.controls.inStream.value;
      const fromEvent = group.controls.fromEvent.value;
      prev[inStream] = fromEvent;
      return prev;
    }, {});
    this.dialog.open(JsonPathTesterDialogComponent, {
      data: {
        toTest: toTest,
      },
    });
  }

  eventTypeSelected(selectedValue: any): void {
    const eventTypeId = selectedValue.option.value;
    this.eventBrokerService
      .getEventTypes([eventTypeId])
      .pipe(take(1))
      .subscribe((res) => {
        if ((res || []).length !== 1) {
          this.alertService.errorSnack('something went horribly wrong with selecting an event broker event type');
        }
        this.populateParametersPathsFromEventType(res[0]);
        this.alertService.successSnack(
          'ParametersPaths have been populated for you. This is very naive and you should not just accept all of the values as is. For the most part, you should only have ids of entity (e.g. partner_id, market_id, account_group_id, and the field(s) that will be used for your Trigger Options when you make your Task Definition. ABSOLUTELY NO PII CAN BE MAPPED HERE. THIS IS WHERE YOU STRIP THAT OUT OF THE EVENT DATA BY NOT INCLUDING IT IN THE PARAMETERSPATHS',
          'Agreed',
          10000000,
        );
      });
  }

  populateParametersPathsFromEventType(eventType: EventTypeInterface): void {
    const parametersPaths = this.form.controls.parametersPaths as UntypedFormArray;
    (eventType?.schema?.properties || []).map((property) => {
      const group = EventTriggerDefinitionFormComponent.getParametersPathFormGroup();
      group.controls.inStream.setValue(`${property.name}.$`);
      group.controls.fromEvent.setValue(`{.${property.name}}`);
      parametersPaths.push(group);
    });
  }

  public import(): void {
    const dialogRef = this.dialog.open(ImportFromJsonDialogComponent);
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((res) => {
        if (res) {
          this.eventTriggerDefinition = res;
          this.populateFormFromEventTriggerDefinition();
        }
      });
  }
}
