import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { map, shareReplay, switchMap, take } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AutomataDevelopmentService, PublishedState, TaskDefinitionInterface } from '@vendasta/automata';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';
import { TaskDefinitionFormComponent } from '../task-definition-form/task-definition-form.component';
import { buildLoggingUrl } from '../conversion';
import { ImportFromJsonDialogComponent } from '../../common/import-from-json-dialog/import-from-json-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-task-definition-page',
  templateUrl: './edit-task-definition-page.component.html',
  styleUrls: ['./edit-task-definition-page.component.scss'],
})
export class EditTaskDefinitionPageComponent implements OnInit {
  buildLoggingUrl = buildLoggingUrl;
  PUBLISHED = PublishedState.PUBLISHED_STATE_PUBLISHED;
  private taskDefinitionId$: Observable<string>;
  taskDefinition$: Observable<TaskDefinitionInterface>;

  resourcesRemoved$$ = new BehaviorSubject<boolean>(false);
  requiredAppsRemoved$$ = new BehaviorSubject<boolean>(false);

  @ViewChild('taskDefinitionForm') taskDefinitionForm: TaskDefinitionFormComponent;
  saveDisabled = false;
  publishedDisabled = false;

  constructor(
    private route: ActivatedRoute,
    private readonly automataDevelopmentService: AutomataDevelopmentService,
    private router: Router,
    private alertService: SnackbarService,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.taskDefinitionId$ = this.route.params.pipe(
      take(1),
      map((params) => params.taskDefinitionId),
    );

    this.taskDefinition$ = this.taskDefinitionId$.pipe(
      take(1),
      switchMap((id) => this.automataDevelopmentService.getTaskDefinition(id)),
      shareReplay(1),
    );
  }

  cancelClicked(value: boolean): void {
    if (value) {
      this.router.navigate(['/automations/task-definitions/list']);
    }
  }

  saveClicked(value: TaskDefinitionInterface): void {
    if (!value) {
      this.alertService.openErrorSnack(`I don't know how we got here, that's on you to figure out`);
      return;
    }
    this.saveDisabled = true;
    this.automataDevelopmentService
      .updateTaskDefinition(value, {
        // Everything but published state cause we don't want to accidentally publish stuff
        paths: [
          'Name',
          'Resource',
          'PreProcessors',
          'InputParameters',
          'OutputParameters',
          'AccessAttributes',
          'TaskType',
          'Internal',
          'AvailableInDefaultAutomations',
          'RequiredScopes',
          'RequiredApps',
          'RequiredSubscriptionFeatures',
          'EventTypeID',
          'EventSubTypeID',
          'SupportedContexts',
          'Choices',
          'StaticValidationRules',
        ],
      })
      .pipe(take(1))
      .subscribe(
        () => {
          this.saveDisabled = false;
          this.alertService.openSuccessSnack('Task definition successfully edited');
          this.cdr.detectChanges();
        },
        (err) => {
          console.error(err);
          this.saveDisabled = false;
          this.alertService.openErrorSnack(err?.error?.message);
          this.cdr.detectChanges();
        },
      );
  }

  copyToClipboard(): void {
    this.taskDefinition$.pipe(take(1)).subscribe((td) => {
      const selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = JSON.stringify(td, null, 4);
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);

      this.alertService.openSuccessSnack('Copied');
    });
  }

  import() {
    const dialogRef = this.dialog.open(ImportFromJsonDialogComponent);
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((res) => {
        if (res) {
          this.requiredAppsRemoved$$.next(res?.requiredApps ? true : false);
          this.resourcesRemoved$$.next(res?.resource ? true : false);
          res.requiredApps = []; // ensure imported empty

          this.taskDefinitionForm.populateFormFromTaskDefinition(res);

          this.taskDefinitionForm.form.controls.resourceUrl.setValue('');
          this.taskDefinitionForm.form.controls.resourceMethod.setValue('');
          this.taskDefinitionForm.form.controls.resourceIsGetter.setValue(false);

          // remove required Apps already there before importing
          this.taskDefinitionForm.requiredAppsFormArray().forEach((value, index) => {
            this.taskDefinitionForm.removeRequiredApp(index);
          });
        }
      });
  }

  publish(): void {
    this.publishedDisabled = true;
    this.taskDefinition$
      .pipe(
        switchMap((td) =>
          this.automataDevelopmentService.updateTaskDefinition(
            { id: td.id, publishedState: PublishedState.PUBLISHED_STATE_PUBLISHED },
            {
              paths: ['PublishedState'],
            },
          ),
        ),
        take(1),
      )
      .subscribe(
        () => {
          this.publishedDisabled = false;
          this.alertService.openSuccessSnack('Published');
          this.cdr.detectChanges();
        },
        (err) => {
          console.error(err);
          this.publishedDisabled = false;
          this.alertService.openErrorSnack(`It didn't work. You probably don't have permissions to publish this`);
          this.cdr.detectChanges();
        },
      );
  }
}
