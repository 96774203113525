import { Component, Input, OnInit } from '@angular/core';
import { ColumnDefinition, CellValueService } from '@vendasta/va-filter2-table';
import moment from 'moment';

@Component({
  template: `
    <span [matTooltip]="momentFormatDate(value, 'ddd MMM D YYYY h:mm:ss a')" class="tooltip-anchor">
      {{ fromNow(value) }}
    </span>
  `,
})
export class DateCellComponent<T> implements OnInit {
  @Input() columnDefinition: ColumnDefinition;

  @Input() element: T;

  value: any;

  constructor(private readonly cellValueService: CellValueService<T>) {}

  ngOnInit(): void {
    this.value = this.cellValueService.getCellValue(
      this.columnDefinition.id,
      this.columnDefinition.field,
      this.element,
    );
  }

  momentFormatDate(date: Date, format: string): string {
    return moment(date).format(format);
  }
  fromNow(date: Date): string {
    return moment(date).fromNow();
  }
}
