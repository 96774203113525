import { Component, OnInit } from '@angular/core';
import { EventTriggerDefinitionTableCustomComponent } from './common-cell.component';

@Component({
  template: `
    <a [routerLink]="['/automations/event-trigger-definitions/edit', id]">{{ name }}</a>
  `,
})
export class NameCellComponent extends EventTriggerDefinitionTableCustomComponent implements OnInit {
  name: string;
  id: string;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.name = this.element.eventTriggerDefinition.name || 'Name not found';
    this.id = this.element.eventTriggerDefinition.eventTypeId;
  }
}
