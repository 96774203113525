import { Component, Input, OnInit } from '@angular/core';
import { CellValueService, ColumnDefinition } from '@vendasta/va-filter2-table';

@Component({
  template: `
    {{ yesno }}
  `,
})
export class BooleanCellComponent<T> implements OnInit {
  @Input() columnDefinition: ColumnDefinition;
  @Input() element: T;
  yesno = 'YES';

  constructor(private readonly cellValueService: CellValueService<T>) {}

  ngOnInit(): void {
    if (!this.cellValueService.getCellValue(this.columnDefinition.id, this.columnDefinition.field, this.element)) {
      this.yesno = 'NO';
    }
  }
}
