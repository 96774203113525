<h2 mat-dialog-title>Select an event trigger definition</h2>
<mat-spinner [strokeWidth]="2" [diameter]="24" *ngIf="loading" />
<mat-dialog-content>
  <EXP__glxy-wrap>
    <div class="list-holder">
      <mat-list *ngIf="eventTriggerDefinitions$ | async as eventTriggerDefinitions">
        <ng-container *ngIf="eventTriggerDefinitions.length > 0">
          <!-- Load the regular list items. -->
          <mat-list-item
            class="list-item"
            *ngFor="let eventTriggerDefinition of eventTriggerDefinitions"
            (click)="selectEventTriggerDefinition(eventTriggerDefinition)"
          >
            <strong matListItemTitle>{{ eventTriggerDefinition.name }}</strong>
          </mat-list-item>

          <!-- When the bottom of the list is hit, load more items. This is consistent with other parts of the platform e.g. invoices. -->
          <ng-container
            *ngIf="{
              hasMore: hasMore$ | async,
              loading: loading,
            } as details"
          >
            <!-- Clever trick courtesy of the businesses project -->
            <div class="load-more-container">
              <div
                *ngIf="!details.loading && details.hasMore"
                class="load-more-check"
                inViewport
                [inViewportOptions]="{ partial: false }"
                (inViewportAction)="loadMore($event)"
              ></div>
            </div>
          </ng-container>
        </ng-container>
      </mat-list>
    </div>
  </EXP__glxy-wrap>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button color="primary" mat-dialog-close tabindex="2">Cancel</button>
</mat-dialog-actions>
