<h2 mat-dialog-title>JSON Path tester</h2>
<p>
  This is not 100% accurate as we have some custom jsonpath functionality, but
  it should work for most usecases
</p>
<mat-dialog-content>
  <div class="content">
    <div class="data">
      <EXP__glxy-wrap>
        <mat-form-field appearance="outline">
          <mat-label>Input Data</mat-label>
          <textarea
            matInput
            [formControl]="inputDataControl"
            rows="30"
          ></textarea>
          <mat-error *ngIf="inputDataControl.hasError('required')">
            Required
          </mat-error>
          <mat-error *ngIf="inputDataControl.hasError('json')">
            Invalid json data provided
          </mat-error>
        </mat-form-field>
      </EXP__glxy-wrap>
    </div>
    <button mat-stroked-button (click)="test()" class="run-button">Test</button>
    <div class="data">
      <EXP__glxy-wrap>
        <mat-form-field appearance="outline">
          <mat-label>Output Data</mat-label>
          <textarea
            matInput
            [formControl]="outputDataControl"
            rows="30"
          ></textarea>
        </mat-form-field>
      </EXP__glxy-wrap>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button color="primary" mat-dialog-close tabindex="2">
    Close
  </button>
</mat-dialog-actions>
