import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
} from '@angular/forms';
import { validationTypeLabels } from './constants';

export function updateControlValidity(input: UntypedFormGroup | UntypedFormArray | UntypedFormControl): void {
  if (input instanceof UntypedFormControl) {
    input.markAsTouched();
    input.updateValueAndValidity();
    return;
  }
  Object.keys(input.controls).forEach((key: string) => {
    const abstractControl = input.controls[key];
    updateControlValidity(abstractControl);
  });
}

export function validateJson(control: AbstractControl): ValidationErrors | null {
  if (validationTypeLabels[control.value]) {
    return null;
  }
  try {
    JSON.parse(control.value);
    return null;
  } catch (e) {
    return { json: true };
  }
}
