<div>
  <form [formGroup]="form">
    <div class="row">
      <mat-card appearance="outlined">
        <mat-card-header>
          <mat-card-title>Base information</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="flex-form">
            <glxy-form-field>
              <glxy-label>Event Type</glxy-label>
              <input type="text" matInput formControlName="eventTypeId" [matAutocomplete]="auto" />
              <mat-autocomplete #auto="matAutocomplete" (optionSelected)="eventTypeSelected($event)">
                <ng-container *ngIf="filteredEventTypes$ | async as eventTypes">
                  <mat-option [value]="eventType.id" *ngFor="let eventType of eventTypes">
                    {{ eventType.name }}
                  </mat-option>
                </ng-container>
              </mat-autocomplete>
            </glxy-form-field>
            <mat-form-field>
              <mat-label>Name</mat-label>
              <input matInput formControlName="name" required />
              <mat-hint>This is not shown to users, just makes it easier to navigate the data</mat-hint>
              <mat-error *ngIf="form.controls.name.hasError('required')">Required</mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Trigger Context ID Format</mat-label>
              <input matInput formControlName="triggerContextIdFormat" required />
              <mat-error *ngIf="form.controls.triggerContextIdFormat.hasError('required')">Required</mat-error>
            </mat-form-field>

            <mat-slide-toggle formControlName="flattenFieldToggle">Flattened Field Config</mat-slide-toggle>
            <div class="hint-text">Used to treat each value in an array as it's own trigger event</div>

            <div *ngIf="form.controls.flattenFieldToggle.value" class="flex-form flattened-field-form">
              <mat-form-field>
                <mat-label>Data Type</mat-label>
                <mat-select formControlName="flattenFieldDataType">
                  <mat-option [value]="flattenedConfigDataType.FLATTENED_CONFIG_DATA_TYPE_STRUCT">Struct</mat-option>
                  <mat-option [value]="flattenedConfigDataType.FLATTENED_CONFIG_DATA_TYPE_STRING">
                    Simple type (int, bool, string, etc.)
                  </mat-option>
                </mat-select>
                <mat-hint>Data type of values inside the array</mat-hint>
              </mat-form-field>

              <mat-form-field
                *ngIf="
                  form.controls.flattenFieldDataType.value !== flattenedConfigDataType.FLATTENED_CONFIG_DATA_TYPE_STRUCT
                "
              >
                <mat-label>Destination path</mat-label>
                <input matInput formControlName="flattenFieldDestinationPath" />
                <mat-hint>Where to put the field on the stream. Should be of form my_string_array.$</mat-hint>
              </mat-form-field>

              <mat-form-field>
                <mat-label>Source path</mat-label>
                <input matInput formControlName="flattenFieldSourcePath" />
                <mat-hint>
                  Where to find the field in the event data. Should be of form {{ '{' }}.my_string{{ '}' }}
                </mat-hint>
              </mat-form-field>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
      <mat-card appearance="outlined">
        <mat-card-header>
          <mat-card-title>Parameters Paths</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <ng-container *ngFor="let group of parametersPathsFormArray().controls; let i = index">
            <div [formGroup]="group" class="flex-form repeated-form">
              <button mat-icon-button (click)="removeParametersPath(i)">
                <mat-icon inline="true">close</mat-icon>
              </button>
              <mat-form-field>
                <mat-label>To be added to stream data</mat-label>
                <input matInput formControlName="inStream" required />
                <mat-hint>should be of form partner_id.$</mat-hint>
                <mat-error *ngIf="group.controls.inStream.hasError('required')">Required</mat-error>
              </mat-form-field>
              <mat-form-field>
                <mat-label>How to read the data from the event</mat-label>
                <input matInput formControlName="fromEvent" required />
                <mat-hint>should be of form {{ '{' }}.partner_id{{ '}' }}</mat-hint>
                <mat-error *ngIf="group.controls.fromEvent.hasError('required')">Required</mat-error>
              </mat-form-field>
              <mat-checkbox formControlName="isFilterKey">Is this parameter path filterable?</mat-checkbox>
              <mat-hint class="hint-text">
                a filterable parameter path can be waited on when used in the delay until trigger action
              </mat-hint>
            </div>
          </ng-container>
          <button mat-stroked-button (click)="addParametersPath()">Add Parameters Path</button>
          <button mat-stroked-button (click)="testParametersPaths()">Test Parameters Path</button>
        </mat-card-content>
      </mat-card>
    </div>
  </form>
  <div style="float: right">
    <button mat-button (click)="cancel()">Cancel</button>
    <button mat-raised-button color="primary" (click)="save()" [disabled]="!this.form.valid || saveDisabled">
      Save
    </button>
  </div>
</div>
