import { Component, OnInit } from '@angular/core';
import { TaskDefinitionsTableCustomComponent } from './common-cell.component';

@Component({
  template: `
    <a [routerLink]="['/automations/task-definitions/edit', id]">{{ name }}</a>
  `,
})
export class NameCellComponent extends TaskDefinitionsTableCustomComponent implements OnInit {
  name: string;
  id: string;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.name = this.element.taskDefinition.name;
    this.id = this.element.taskDefinition.id;
  }
}
