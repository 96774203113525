<glxy-page>
  <glxy-page-toolbar>
    <glxy-page-nav>
      <glxy-page-nav-button
        previousPageTitle="Task Definitions"
        [previousPageUrl]="'/automations/task-definitions/list'"
      />
    </glxy-page-nav>

    <glxy-page-title>Edit Task Definition</glxy-page-title>

    <glxy-page-actions>
      <ng-container *ngIf="taskDefinition$ | async as taskDefinition">
        <ng-container *ngIf="!!taskDefinition.resource">
          <a
            [href]="buildLoggingUrl(taskDefinition.resource.url)"
            target="_blank"
            glxyTooltip="If there are no logs at the end of this link, update the cloud function to have the latest version of resource_helper"
          >
            <button mat-stroked-button>Go to Logs</button>
          </a>
        </ng-container>
      </ng-container>
      <button mat-stroked-button (click)="import()">Import</button>
      <button mat-stroked-button (click)="copyToClipboard()">Copy To Clipboard</button>
      <ng-container *ngIf="taskDefinition$ | async as taskDefinition">
        <button
          mat-stroked-button
          color="primary"
          *ngIf="taskDefinition.publishedState !== PUBLISHED"
          [disabled]="publishedDisabled"
          (click)="publish()"
        >
          Publish
        </button>
      </ng-container>
    </glxy-page-actions>
  </glxy-page-toolbar>
  <glxy-page-wrapper maxWidth="1160">
    <ng-container *ngIf="taskDefinition$ | async as taskDefinition; else loading">
      <app-task-definition-form
        [taskDefinition]="taskDefinition"
        [saveDisabled]="saveDisabled"
        [editMode]="true"
        [requiredAppsRemoved]="requiredAppsRemoved$$ | async"
        [resourcesRemoved]="resourcesRemoved$$ | async"
        #taskDefinitionForm
        (cancelClicked)="cancelClicked($event)"
        (saveClicked)="saveClicked($event)"
      />
    </ng-container>
    <ng-template #loading>
      <mat-card appearance="outlined" class="stencil-shimmer" />
    </ng-template>
  </glxy-page-wrapper>
</glxy-page>
