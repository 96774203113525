import { Component, OnInit } from '@angular/core';
import { TaskDefinitionsTableCustomComponent } from './common-cell.component';

@Component({
  template: `
    <div>{{ display }}</div>
  `,
})
export class OutputParamsCellComponent extends TaskDefinitionsTableCustomComponent implements OnInit {
  display: string;

  constructor() {
    super();
  }

  ngOnInit(): void {
    const ids = this.element?.taskDefinition?.outputParameters?.map((ip) => ip.settings.id);
    this.display = (ids || []).join(', ');
  }
}
