import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DataType, ValidationType } from '@vendasta/automata';

export interface InputParameterOptionInterface {
  id: string;
  name: string;
  pathKey: string;
  pathValue: string;
  required: boolean;
  validationType: ValidationType;
  dataType: DataType;
}

const commonInputParameters: InputParameterOptionInterface[] = [
  {
    id: 'idempotency_key',
    name: 'Idempotency Key',
    pathKey: 'idempotency_key.$',
    pathValue: '{.current_activity_details.idempotency_key}',
    required: true,
    validationType: ValidationType.VALIDATION_TYPE_SYSTEM,
    dataType: DataType.DATA_TYPE_UNKNOWN,
  },
  {
    id: 'step_id',
    name: 'Step ID',
    pathKey: 'step_id.$',
    pathValue: '{.current_activity_details.step_id}',
    required: true,
    validationType: ValidationType.VALIDATION_TYPE_SYSTEM,
    dataType: DataType.DATA_TYPE_UNKNOWN,
  },
  {
    id: 'started_processing',
    name: 'Started Processing',
    pathKey: 'started_processing.$',
    pathValue: '{.current_activity_details.started_processing}',
    required: true,
    validationType: ValidationType.VALIDATION_TYPE_SYSTEM,
    dataType: DataType.DATA_TYPE_UNKNOWN,
  },
  {
    id: 'entity_id',
    name: 'Entity ID',
    pathKey: 'entity_id.$',
    pathValue: '{.current_activity_details.entity_id}',
    required: true,
    validationType: ValidationType.VALIDATION_TYPE_SYSTEM,
    dataType: DataType.DATA_TYPE_UNKNOWN,
  },
  {
    id: 'context',
    name: 'Context',
    pathKey: 'context.$',
    pathValue: '{.current_activity_details.context}',
    required: true,
    validationType: ValidationType.VALIDATION_TYPE_SYSTEM,
    dataType: DataType.DATA_TYPE_STRING,
  },
  {
    id: 'partner_id',
    name: 'Partner ID',
    pathKey: 'partner_id.$',
    pathValue: '{.partner_id}',
    required: true,
    validationType: ValidationType.VALIDATION_TYPE_STREAM,
    dataType: DataType.DATA_TYPE_UNKNOWN,
  },
  {
    id: 'market_id',
    name: 'Market ID',
    pathKey: 'market_id.$',
    pathValue: '{.market_id}',
    required: true,
    validationType: ValidationType.VALIDATION_TYPE_STREAM,
    dataType: DataType.DATA_TYPE_UNKNOWN,
  },
  {
    id: 'account_group_id',
    name: 'Account Group ID',
    pathKey: 'account_group_id.$',
    pathValue: '{.account_group_id}',
    required: true,
    validationType: ValidationType.VALIDATION_TYPE_STREAM,
    dataType: DataType.DATA_TYPE_ACCOUNT_GROUP_ID,
  },
  {
    id: 'user_id',
    name: 'User ID',
    pathKey: 'user_id.$',
    pathValue: '{.user_id}',
    required: true,
    validationType: ValidationType.VALIDATION_TYPE_STREAM,
    dataType: DataType.DATA_TYPE_SMB_USER_ID,
  },
  {
    id: 'order_id',
    name: 'Order ID',
    pathKey: 'order_id.$',
    pathValue: '{.order_id}',
    required: true,
    validationType: ValidationType.VALIDATION_TYPE_STREAM,
    dataType: DataType.DATA_TYPE_ORDER_ID,
  },
];

@Component({
  selector: 'app-add-input-parameter-dialog',
  templateUrl: './add-input-parameter-dialog.component.html',
  styleUrls: ['./add-input-parameter-dialog.component.scss'],
})
export class AddInputParameterDialogComponent {
  commonInputParameters = commonInputParameters;
  constructor(public dialogRef: MatDialogRef<AddInputParameterDialogComponent, InputParameterOptionInterface>) {}

  selectParam(p: InputParameterOptionInterface): void {
    this.dialogRef.close(p);
  }
}
