import { Component } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { validateJson } from '../form-helpers';

@Component({
  selector: 'app-import-from-json-dialog',
  templateUrl: './import-from-json-dialog.component.html',
  styleUrls: ['./import-from-json-dialog.component.scss'],
})
export class ImportFromJsonDialogComponent {
  dataControl = new UntypedFormControl('', [Validators.required, validateJson]);
  constructor(public dialogRef: MatDialogRef<ImportFromJsonDialogComponent, Record<string, any>>) {}

  import(): void {
    this.dialogRef.close(JSON.parse(this.dataControl.value));
  }
}
