import { Injectable } from '@angular/core';
import {
  TaskDefinition,
  ListTaskDefinitionsRequestFiltersInterface,
  AutomataDevelopmentService,
} from '@vendasta/automata';
import { LoadRequest, TableDataService } from '@vendasta/va-filter2-table';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, shareReplay, take } from 'rxjs/operators';
import { Filters } from './task-defintions-filters.service';

export interface TaskDefinitionRow {
  taskDefinition: TaskDefinition;
  rowNumber: number;
}

function convertFilters(filters: Filters): ListTaskDefinitionsRequestFiltersInterface {
  const f: ListTaskDefinitionsRequestFiltersInterface = {
    internal: filters.internal,
    onlyAvailableInDefaultAutomations: filters.onlyDefault,
  };

  if (filters.taskType) {
    f.taskType = filters.taskType;
  }
  if (filters.publishedState) {
    f.publishedState = filters.publishedState;
  }

  if (filters.searchTerm) {
    f.name = filters.searchTerm;
  }

  if (filters.supportedContexts) {
    f.supportedContexts = filters.supportedContexts;
  }

  return f;
}

@Injectable()
export class TaskDefinitionsTableService implements TableDataService<TaskDefinitionRow> {
  private readonly totalResults$$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public readonly totalResults$: Observable<number> = this.totalResults$$.asObservable();
  private cursors: { [id: number]: string } = { 0: '' };

  constructor(private readonly automataDevelopmentService: AutomataDevelopmentService) {}

  initialize(): void {
    // nothing to initialize currently
  }

  load(request: LoadRequest): Observable<TaskDefinitionRow[]> {
    if (request.pageIndex === 0) {
      this.cursors = { 0: '' };
    }
    const nextCursor = this.cursors[request.pageIndex] || '';
    const filters = convertFilters(request.filters);
    return this.listTaskDefinitions(nextCursor, filters, request.pageSize, request.pageIndex);
  }

  listTaskDefinitions(
    nextCursor: string,
    filters: ListTaskDefinitionsRequestFiltersInterface,
    pageSize: number,
    pageIndex: number,
  ): Observable<TaskDefinitionRow[]> {
    const listResponse$ = this.automataDevelopmentService
      .listTaskDefinitions(filters, null, nextCursor, pageSize)
      .pipe(take(1), shareReplay(1));

    listResponse$.pipe(take(1)).subscribe((resp) => {
      this.cursors[pageIndex + 1] = resp.nextCursor;
      this.totalResults$$.next(resp.totalResults);
    });
    let current = pageSize * pageIndex;
    return listResponse$.pipe(
      map((listResponse) => {
        return listResponse.results?.map((t) => {
          current++;
          return {
            taskDefinition: t.taskDefinition,
            rowNumber: current,
          };
        });
      }),
    );
  }
}
