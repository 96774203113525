<h2 mat-dialog-title>Add a common input parameter</h2>
<mat-dialog-content>
  <ng-container *ngFor="let param of commonInputParameters">
    <button mat-button (click)="selectParam(param)">{{ param.name }}</button>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button color="primary" mat-dialog-close tabindex="2">
    Cancel
  </button>
</mat-dialog-actions>
