import { Component, OnInit } from '@angular/core';
import { TaskDefinitionsTableCustomComponent } from './common-cell.component';
import { publishedStateLabels } from '../../../../common/constants';

@Component({
  template: `
    <div>{{ display }}</div>
  `,
})
export class PublishedStateCellComponent extends TaskDefinitionsTableCustomComponent implements OnInit {
  display: string;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.display = publishedStateLabels[this.element.taskDefinition.publishedState];
  }
}
