import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import {
  ChoicesInterface,
  EventTriggerDefinitionInterface,
  InputParameterInterface,
  OutputParameterInterface,
  PublishedState,
  TaskDefinitionInterface,
  TaskType,
  ValidationType,
  WorkflowStepInterface,
} from '@vendasta/automata';

import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, ParamMap } from '@angular/router';
import {
  AppKeyInterface,
  CallOptionsInterface,
  ResourceInterface,
} from '@vendasta/automata/lib/_internal/interfaces/task-definition.interface';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';
import { ConsentAdminApiService } from '@vendasta/sso';
import { Observable, Subject } from 'rxjs';
import { map, pairwise, shareReplay, startWith, take, takeUntil, tap } from 'rxjs/operators';
import {
  dataTypeLabels,
  dataTypes,
  publishedStateLabels,
  publishedStates,
  supportedContexts,
  supportedContextsLabels,
  taskTypeLabels,
  taskTypes,
  validationTypeLabels,
  validationTypes,
} from '../../common/constants';
import { updateControlValidity } from '../../common/form-helpers';
import { ImportFromJsonDialogComponent } from '../../common/import-from-json-dialog/import-from-json-dialog.component';
import { JsonPathTesterDialogComponent } from '../../common/json-path-tester-dialog/json-path-tester-dialog.component';
import { AddInputParameterDialogComponent } from './add-input-parameter-dialog/add-input-parameter-dialog.component';

@Component({
  selector: 'app-task-definition-form',
  templateUrl: './task-definition-form.component.html',
  styleUrls: ['./task-definition-form.component.scss'],
})
export class TaskDefinitionFormComponent implements OnInit, OnDestroy {
  @Input()
  taskDefinition: TaskDefinitionInterface = {};

  @Output() cancelClicked: EventEmitter<boolean> = new EventEmitter();
  @Output() saveClicked: EventEmitter<TaskDefinitionInterface> = new EventEmitter();

  @Input() saveDisabled: boolean;
  @Input() sourceEventTriggerDefinition: EventTriggerDefinitionInterface;
  @Input() taskType: string;

  @Input() editMode: boolean;
  @Input() requiredAppsRemoved: boolean;
  @Input() resourcesRemoved: boolean;

  form: FormGroup = new FormGroup({
    id: new FormControl('', Validators.required), // Only used for create
    name: new FormControl('', Validators.required),
    taskType: new FormControl('', taskTypeValidator),
    eventTypeId: new FormControl(''),
    eventSubTypeId: new FormControl(''),
    internal: new FormControl(null),
    availableInDefault: new FormControl(null),
    supportedContexts: new FormControl([]),
    resourceUrl: new FormControl(''),
    resourceMethod: new FormControl('', methodValidator),
    resourceIsGetter: new FormControl(''),
    resourceRetryOptions: new FormArray([]),
    preProcessors: new FormArray([]),
    requiredApps: new FormArray([]),
    inputParameters: new FormArray([]),
    outputParameters: new FormArray([]),
    accessAttributes: new FormArray([]),
    requiredScopes: new FormControl([]),
    requiredSubscriptionFeatures: new FormArray([]),
    choices: new FormControl(null),
    staticValidationChoices: new FormControl(null),
  });

  taskTypes = taskTypes;
  taskTypeLabels = taskTypeLabels;
  TRIGGER = TaskType.TASK_TYPE_TRIGGER;
  publishedStateLabels = publishedStateLabels;
  publishedStates = publishedStates;
  validationTypes = validationTypes;
  validationTypeLabels = validationTypeLabels;
  supportedContexts = supportedContexts;
  supportedContextLabels = supportedContextsLabels;
  dataTypes = dataTypes;
  dataTypeLabels = dataTypeLabels;
  DRAFT = PublishedState.PUBLISHED_STATE_DRAFT;

  destroyed$$: Subject<void> = new Subject();
  readonly destroyed$ = this.destroyed$$.asObservable();

  scopeOptions$: Observable<string[]> = this.scopes.listScopes({ pageSize: 1000 }).pipe(
    take(1),
    map((resp) => resp?.scopes?.map((s) => s.id)),
    shareReplay(),
  );

  private static getRetryOptionsFormGroup(): FormGroup {
    return new FormGroup({
      statusCode: new FormControl(''),
      maximumAttempts: new FormControl('', [Validators.required, nonNegativeIntegerValidator]),
    });
  }

  private static getParameterFormGroup(): FormGroup {
    return new FormGroup({
      id: new FormControl('', Validators.required),
      name: new FormControl('', Validators.required),
      dataType: new FormControl('', dataTypeValidator),
      repeated: new FormControl(false),
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private static getPreProcessorKeyValueFormGroup(i: number): FormGroup {
    return new FormGroup({
      pathKey: new FormControl('', Validators.required),
      pathValue: new FormControl('', Validators.required),
    });
  }

  private static getPreProcessorChoices(): FormGroup {
    return new FormGroup({
      rules: new FormArray([]),
      default: new FormControl(''),
    });
  }

  private static getPreProcessorChoicesRules(): FormGroup {
    return new FormGroup({
      variablePath: new FormControl(''),
      comparison: new FormControl(''),
      value: new FormControl(''),
      next: new FormControl(''),
    });
  }

  private static getInputParameterFormGroup(destroyed$: Observable<void>): FormGroup {
    const pfg = this.getParameterFormGroup();
    const fg = new FormGroup({
      settings: pfg,
      required: new FormControl(null),
      pathKey: new FormControl('', Validators.required),
      pathValue: new FormControl('', Validators.required),
      validationType: new FormControl('', validationTypeValidator),
    });

    pfg.controls['id'].valueChanges
      .pipe(startWith(undefined), pairwise(), takeUntil(destroyed$))
      .subscribe(([prevId, curId]) => {
        const prevKey = `${prevId}.$`;
        const prevValue = `{.${prevId}}`;
        if (prevId === undefined || fg.controls.pathKey.value === prevKey) {
          fg.controls.pathKey.setValue(`${curId}.$`);
          fg.controls.pathKey.markAsDirty();
        }
        if (prevId === undefined || fg.controls.pathValue.value === prevValue) {
          fg.controls.pathValue.setValue(`{.${curId}}`);
          fg.controls.pathValue.markAsDirty();
        }
      });

    return fg;
  }

  private static getOutputParameterFormGroup(destroyed$: Observable<void>): FormGroup {
    const pfg = this.getParameterFormGroup();
    const fg = new FormGroup({
      settings: pfg,
      pathKey: new FormControl('', Validators.required),
      pathValue: new FormControl('', Validators.required),
      optional: new FormControl(null),
      isEntity: new FormControl(null),
    });

    pfg.controls['id'].valueChanges
      .pipe(startWith(undefined), pairwise(), takeUntil(destroyed$))
      .subscribe(([prevId, curId]) => {
        const prevKey = `${prevId}.$`;
        const prevValue = `{.${prevId}}`;
        if (prevId === undefined || fg.controls.pathKey.value === prevKey) {
          fg.controls.pathKey.setValue(`${curId}.$`);
          fg.controls.pathKey.markAsDirty();
        }
        if (prevId === undefined || fg.controls.pathValue.value === prevValue) {
          fg.controls.pathValue.setValue(`{.${curId}}`);
          fg.controls.pathValue.markAsDirty();
        }
      });

    return fg;
  }

  private static getPreProcessorDataFormGroup(): FormGroup {
    return new FormGroup({
      id: new FormControl('', Validators.required),
      name: new FormControl('', Validators.required),
      taskDefinitionId: new FormControl('', Validators.required),
      data: new FormArray([]),
      choices: new FormControl(this.getPreProcessorChoices()),
    });
  }

  private static getRequiredAppFormGroup(): FormGroup {
    return new FormGroup({
      appId: new FormControl('', Validators.required),
      editionId: new FormControl(''),
    });
  }

  constructor(
    private readonly alertService: SnackbarService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private scopes: ConsentAdminApiService,
  ) {}

  resourceRetryOptionsArray(): FormArray {
    return this.form.controls.resourceRetryOptions as FormArray;
  }

  inputParametersFormArray(): FormArray {
    return this.form.controls.inputParameters as FormArray;
  }

  preProcessorsFormArray(): FormArray {
    return this.form.controls.preProcessors as FormArray;
  }

  preProcessorDataFormArray(i: number): FormArray {
    return (this.preProcessorsFormArray().controls[i] as FormArray).controls['data'];
  }

  preProcessorChoicesFormArray(i: number): FormArray {
    return (this.preProcessorsFormArray().controls[i] as FormArray).controls['choices'].value.controls['rules'];
  }

  requiredAppsFormArray(): AbstractControl[] {
    return (this.form.controls.requiredApps as FormArray).controls;
  }

  outputParametersFormArray(): FormArray {
    return this.form.controls.outputParameters as FormArray;
  }

  accessAttributesFormArray(): FormArray {
    return this.form.controls.accessAttributes as FormArray;
  }

  requiredSubscriptionFeaturesFormArray(): FormArray {
    return this.form.controls.requiredSubscriptionFeatures as FormArray;
  }

  ngOnInit(): void {
    if (this.taskDefinition?.id) {
      this.form.controls.id.disable();
    }

    this.populateFormFromTaskDefinition(this.taskDefinition);

    this.route.queryParamMap.pipe(take(1)).subscribe((paramMap: ParamMap) => {
      if (paramMap.get('import')) {
        this.import();
      }
    });
    this.form.controls.taskType.valueChanges
      .pipe(
        tap((taskType) => {
          if (taskType === this.TRIGGER) {
            this.form.controls.eventTypeId.setValidators([Validators.required]);
          } else {
            this.form.controls.eventTypeId.setValidators([]);
            this.outputParametersFormArray().controls.forEach((op) => {
              op.get('isEntity')?.setValue(false);
            });
          }
        }),
        takeUntil(this.destroyed$$),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroyed$$.next();
  }

  populateFormFromTaskDefinition(taskDefinition: TaskDefinitionInterface): void {
    this.form.controls.id.setValue(taskDefinition?.id || 'TaskDefinition-');
    this.form.controls.name.setValue(taskDefinition?.name);
    this.form.controls.taskType.setValue(taskDefinition?.taskType || this.taskType);
    this.form.controls.eventTypeId.setValue(
      taskDefinition?.eventTypeId || this.sourceEventTriggerDefinition?.eventTypeId,
    );
    this.form.controls.eventSubTypeId.setValue(taskDefinition?.eventSubTypeId);
    this.form.controls.internal.setValue(taskDefinition?.internal);
    this.form.controls.availableInDefault.setValue(taskDefinition?.availableInDefaultAutomations);
    this.form.controls.supportedContexts.setValue(taskDefinition?.supportedContexts);
    this.form.controls.resourceUrl.setValue(taskDefinition?.resource?.url);
    this.form.controls.resourceMethod.setValue(taskDefinition?.resource?.method);
    this.form.controls.resourceIsGetter.setValue(taskDefinition?.resource?.isGetter);
    this.form.controls.requiredScopes.setValue(taskDefinition?.requiredScopes);

    (this.form.controls.resourceRetryOptions as FormArray).clear();
    (taskDefinition?.resource?.callOptions?.retryPolicyOptions || []).map((opt) => {
      const fg = TaskDefinitionFormComponent.getRetryOptionsFormGroup();
      fg.controls.statusCode.setValue(opt.statusCode);
      fg.controls.maximumAttempts.setValue(opt.maximumAttempts);
      (this.form.controls.resourceRetryOptions as FormArray).push(fg);
    });

    (this.form.controls.preProcessors as FormArray).clear();

    (taskDefinition?.preProcessors || []).map((pp) => {
      const fg = TaskDefinitionFormComponent.getPreProcessorDataFormGroup();
      fg.controls.id.setValue(pp.id);
      fg.controls.name.setValue(pp.name);
      fg.controls.taskDefinitionId.setValue(pp.taskDefinitionId);
      if (pp.data) {
        const obj = JSON.parse(pp.data);
        const entries = Object.entries(obj);
        for (const index in entries) {
          (fg.controls.data as FormArray).push(TaskDefinitionFormComponent.getPreProcessorKeyValueFormGroup(0));
          (fg.controls.data as FormArray).controls[index].setValue({
            pathKey: entries[index][0],
            pathValue: entries[index][1],
          });
        }
      }
      if (pp.choices) {
        const defaultNextStep = pp.choices.defaultNextStep;
        const rules = pp.choices.rules;
        fg.controls.choices.value.controls.default.setValue(defaultNextStep);
        for (const rule in rules || []) {
          fg.controls.choices.value.controls.rules.push(TaskDefinitionFormComponent.getPreProcessorChoicesRules());
          const r = {
            variablePath: rules[rule].variablePath || '',
            value: rules[rule].value || '',
            next: rules[rule].next || '',
            comparison: rules[rule].comparison || '',
          };
          fg.controls.choices.value.controls.rules.controls[rule].setValue(r);
        }
      }
      (this.form.controls.preProcessors as FormArray).push(fg);
    });

    (taskDefinition?.requiredApps || []).forEach((appKey) => {
      const fg = TaskDefinitionFormComponent.getRequiredAppFormGroup();
      fg.controls.appId.setValue(appKey.appId);
      fg.controls.editionId.setValue(appKey.editionId);
      (this.form.controls.requiredApps as FormArray).push(fg);
    });

    (this.form.controls.inputParameters as FormArray).clear();
    (taskDefinition?.inputParameters || []).map((ip) => {
      const fg = TaskDefinitionFormComponent.getInputParameterFormGroup(this.destroyed$);
      (fg.controls.settings as FormGroup).controls.id.setValue(ip.settings.id);
      (fg.controls.settings as FormGroup).controls.name.setValue(ip.settings.name);
      (fg.controls.settings as FormGroup).controls.dataType.setValue(ip.settings.dataType);
      (fg.controls.settings as FormGroup).controls.repeated.setValue(ip.settings.repeated);
      fg.controls.required.setValue(ip.required);

      if (ip.path) {
        const obj = JSON.parse(ip.path);
        const entries = Object.entries(obj);
        if ((entries || []).length !== 1) {
          this.alertService.errorSnack('something is completely messed up with this input parameter (check the logs)');
          console.log(ip);
        } else {
          fg.controls.pathKey.setValue(entries[0][0]);
          fg.controls.pathValue.setValue(entries[0][1]);
        }
      }

      fg.controls.validationType.setValue(ip.validationType);
      (this.form.controls.inputParameters as FormArray).push(fg);
    });

    (this.form.controls.outputParameters as FormArray).clear();
    (taskDefinition?.outputParameters || []).map((op) => {
      const fg = TaskDefinitionFormComponent.getOutputParameterFormGroup(this.destroyed$);
      (fg.controls.settings as FormGroup).controls.id.setValue(op.settings.id);
      (fg.controls.settings as FormGroup).controls.name.setValue(op.settings.name);
      (fg.controls.settings as FormGroup).controls.dataType.setValue(op.settings.dataType);
      (fg.controls.settings as FormGroup).controls.repeated.setValue(op.settings.repeated);
      fg.controls.optional.setValue(op.optional);
      if (taskDefinition?.taskType === this.TRIGGER) {
        fg.controls.isEntity.setValue(op.isEntity);
      }
      if (op.path) {
        const obj = JSON.parse(op.path);
        const entries = Object.entries(obj);
        if ((entries || []).length !== 1) {
          this.alertService.errorSnack('something is completely messed up with this output parameter (check the logs)');
          console.log(op);
        } else {
          fg.controls.pathKey.setValue(entries[0][0]);
          fg.controls.pathValue.setValue(entries[0][1]);
        }
      }
      (this.form.controls.outputParameters as FormArray).push(fg);
    });

    (this.form.controls.accessAttributes as FormArray).clear();
    (taskDefinition?.accessAttributes || []).map((aa) => {
      (this.form.controls.accessAttributes as FormArray).push(new FormControl(aa, Validators.required));
    });

    (this.form.controls.requiredSubscriptionFeatures as FormArray).clear();
    (taskDefinition?.requiredSubscriptionFeatures || []).map((rsf) => {
      (this.form.controls.requiredSubscriptionFeatures as FormArray).push(new FormControl(rsf, Validators.required));
    });

    //populate the input parameters from the eventTriggerDefinitionId if provided
    if (this.sourceEventTriggerDefinition?.parametersPaths) {
      const parametersPaths = JSON.parse(this.sourceEventTriggerDefinition.parametersPaths);
      Object.entries(parametersPaths).map((pp) => {
        const fg = TaskDefinitionFormComponent.getInputParameterFormGroup(this.destroyed$);
        const variable = getVarNameFromEventTriggerDefinitionParamPathKey(pp[0]);
        (fg.controls.settings as FormGroup).controls.id.setValue(variable);
        fg.controls.pathKey.setValue(variable + '.$');
        fg.controls.pathValue.setValue(`{.${variable}}`);

        fg.controls.validationType.setValue(ValidationType.VALIDATION_TYPE_STREAM);
        (this.form.controls.inputParameters as FormArray).push(fg);
      });
    }

    if (taskDefinition?.choices?.rules?.length > 0) {
      if (taskDefinition?.choices?.rules?.length > 1) {
        this.alertService.errorSnack(
          'The base rule should be an AND or an OR but you have multiple rules instead of one of those for static rules',
        );
      }
      this.form.controls.choices.setValue(taskDefinition?.choices?.rules?.[0]);
    }
    if (taskDefinition?.staticValidationRules?.rules?.length > 0) {
      if (taskDefinition?.staticValidationRules?.rules?.length > 1) {
        this.alertService.errorSnack(
          'The base rule should be an AND or an OR but you have multiple rules instead of one of those for static validation rules',
        );
      }
      this.form.controls.staticValidationChoices.setValue(taskDefinition?.staticValidationRules?.rules?.[0]);
    }
  }

  addRetryOptions(): void {
    (this.form.controls.resourceRetryOptions as FormArray).push(TaskDefinitionFormComponent.getRetryOptionsFormGroup());
  }

  removeRetryOption(index: number): void {
    (this.form.controls.resourceRetryOptions as FormArray).removeAt(index);
  }

  addInputParameter(): void {
    (this.form.controls.inputParameters as FormArray).push(
      TaskDefinitionFormComponent.getInputParameterFormGroup(this.destroyed$),
    );
  }

  addPreProcessor(): void {
    (this.form.controls.preProcessors as FormArray).push(TaskDefinitionFormComponent.getPreProcessorDataFormGroup());
  }

  addRequiredApp(): void {
    (this.form.controls.requiredApps as FormArray).push(TaskDefinitionFormComponent.getRequiredAppFormGroup());
  }

  removeRequiredApp(index: number): void {
    (this.form.controls.requiredApps as FormArray).removeAt(index);
  }

  addPreProcessorKeyValue(i: number): void {
    ((this.form.controls.preProcessors as FormArray).controls[i] as FormArray).controls['data'].push(
      TaskDefinitionFormComponent.getPreProcessorKeyValueFormGroup(i),
    );
  }

  addPreProcessorRule(i: number): void {
    ((this.form.controls.preProcessors as FormArray).controls[i] as FormArray).controls['choices'].value.controls[
      'rules'
    ].push(TaskDefinitionFormComponent.getPreProcessorChoicesRules());
  }

  removeInputParameter(index: number): void {
    (this.form.controls.inputParameters as FormArray).removeAt(index);
  }

  removePreProcessor(index: number): void {
    (this.form.controls.preProcessors as FormArray).removeAt(index);
  }

  removePreProcessorKeyValue(i: number, j: number): void {
    (this.preProcessorsFormArray().controls[i] as FormArray).controls['data'].removeAt(j);
  }

  removePreProcessorRule(i: number, j: number): void {
    this.preProcessorChoicesFormArray(i).removeAt(j);
  }

  addOutputParameter(): void {
    (this.form.controls.outputParameters as FormArray).push(
      TaskDefinitionFormComponent.getOutputParameterFormGroup(this.destroyed$),
    );
  }

  removeOutputParameter(index: number): void {
    (this.form.controls.outputParameters as FormArray).removeAt(index);
  }

  addAccessAttribute(): void {
    (this.form.controls.accessAttributes as FormArray).push(new FormControl('', Validators.required));
  }

  removeAccessAttribute(index: number): void {
    (this.form.controls.accessAttributes as FormArray).removeAt(index);
  }

  addRequiredSubscriptionFeatures(): void {
    (this.form.controls.requiredSubscriptionFeatures as FormArray).push(new FormControl('', Validators.required));
  }

  removeRequiredSubscriptionFeatures(index: number): void {
    (this.form.controls.requiredSubscriptionFeatures as FormArray).removeAt(index);
  }

  cancel(): void {
    this.cancelClicked.emit(true);
  }

  save(): void {
    if (!this.form.valid) {
      updateControlValidity(this.form);
      this.alertService.errorSnack('You need to correctly fill out the form :/');
      return;
    }

    let resource: ResourceInterface;
    if (this.form.controls.resourceUrl.value) {
      resource = {};
      resource.url = this.form.controls.resourceUrl.value.trim();
      resource.method = this.form.controls.resourceMethod.value;
      resource.isGetter = this.form.controls.resourceIsGetter.value;

      const callOptions: CallOptionsInterface = { retryPolicyOptions: [] };
      ((this.form.controls.resourceRetryOptions as FormArray).controls || []).map((control) => {
        const group = control as FormGroup;
        callOptions.retryPolicyOptions.push({
          statusCode: group.controls.statusCode.value,
          maximumAttempts: group.controls.maximumAttempts.value,
        });
      });
      if (callOptions.retryPolicyOptions.length > 0) {
        resource.callOptions = callOptions;
      }
    }

    const accessAttributes: string[] = [];
    (this.form.controls.accessAttributes as FormArray).controls.map((c) => accessAttributes.push(c.value));

    const requiredScopes: string[] = this.form.controls.requiredScopes.value;

    const requiredSubscriptionFeatures: string[] = [];
    (this.form.controls.requiredSubscriptionFeatures as FormArray).controls.map((c) =>
      requiredSubscriptionFeatures.push(c.value),
    );

    const preProcessors: WorkflowStepInterface[] = [];
    (this.form.controls.preProcessors as FormArray).controls.map((controls) => {
      const group = controls as FormGroup;
      const obj = {};
      const other_obj = [];
      for (const ctrl of (group.controls.data as FormArray).controls) {
        obj[ctrl.value.pathKey] = ctrl.value.pathValue;
      }

      for (const rule of (group.controls.choices.value.controls.rules as FormArray).controls) {
        other_obj.push(rule.value);
      }
      let defaultValue = '';
      if (other_obj.length > 0) {
        defaultValue = group.controls.choices.value.controls.default.value;
      }
      const choices = {
        rules: other_obj,
        defaultNextStep: defaultValue,
      };
      const jsonParam = JSON.stringify(obj);
      preProcessors.push({
        id: group.controls.id.value,
        name: group.controls.name.value,
        taskDefinitionId: group.controls.taskDefinitionId.value,
        data: jsonParam,
        choices: choices,
      });
    });

    const requiredApps: AppKeyInterface[] = (this.form.controls.requiredApps as FormArray).controls.map((fg) => {
      const group = fg as FormGroup;
      return {
        appId: group.controls.appId.value,
        editionId: group.controls.editionId.value,
      };
    });

    const inputParameters: InputParameterInterface[] = [];
    (this.form.controls.inputParameters as FormArray).controls.map((controls) => {
      const group = controls as FormGroup;
      const settingsGroup = group.controls.settings as FormGroup;
      const obj = {};
      obj[group.controls.pathKey.value] = group.controls.pathValue.value;
      const jsonParam = JSON.stringify(obj);
      inputParameters.push({
        settings: {
          id: settingsGroup.controls.id.value,
          name: settingsGroup.controls.name.value,
          dataType: settingsGroup.controls.dataType.value,
          repeated: settingsGroup.controls.repeated.value,
        },
        required: group.controls.required.value,
        path: jsonParam,
        validationType: group.controls.validationType.value,
      });
    });

    const outputParameters: OutputParameterInterface[] = [];
    (this.form.controls.outputParameters as FormArray).controls.map((controls) => {
      const group = controls as FormGroup;
      const settingsGroup = group.controls.settings as FormGroup;
      const obj = {};
      obj[group.controls.pathKey.value] = group.controls.pathValue.value;
      const jsonParam = JSON.stringify(obj);
      outputParameters.push({
        settings: {
          id: settingsGroup.controls.id.value,
          name: settingsGroup.controls.name.value,
          dataType: settingsGroup.controls.dataType.value,
          repeated: settingsGroup.controls.repeated.value,
        },
        path: jsonParam,
        optional: group.controls.optional.value,
        isEntity: group.controls.isEntity.value,
      });
    });

    let eventTypeId = '';
    if (this.form.controls.taskType.value === this.TRIGGER) {
      eventTypeId = this.form.controls.eventTypeId.value;
    }

    let choices: ChoicesInterface;
    if (this.form.controls.choices.value) {
      if (this.form.controls.taskType.value !== TaskType.TASK_TYPE_TRIGGER) {
        this.alertService.errorSnack('You can only have choices for triggers');
        return;
      }
      choices = {
        rules: [this.form.controls.choices.value],
        defaultNextStep: '',
      };
    }

    let staticValidationChoices: ChoicesInterface;
    if (this.form.controls.staticValidationChoices.value) {
      staticValidationChoices = {
        rules: [this.form.controls.staticValidationChoices.value],
        defaultNextStep: '',
      };
    }

    const newTaskDefinition: TaskDefinitionInterface = {
      id: this.form.controls.id.value,
      name: this.form.controls.name.value,
      resource: resource,
      inputParameters: inputParameters,
      outputParameters: outputParameters,
      taskType: this.form.controls.taskType.value,
      eventTypeId: eventTypeId,
      eventSubTypeId: this.form.controls.eventSubTypeId.value,
      internal: this.form.controls.internal.value,
      availableInDefaultAutomations: this.form.controls.availableInDefault.value,
      publishedState: this.taskDefinition?.publishedState || PublishedState.PUBLISHED_STATE_DRAFT,
      accessAttributes: accessAttributes,
      requiredScopes: requiredScopes,
      requiredApps: requiredApps,
      requiredSubscriptionFeatures: requiredSubscriptionFeatures,
      preProcessors: preProcessors,
      supportedContexts: this.form.controls.supportedContexts.value,
      choices: choices,
      staticValidationRules: staticValidationChoices,
    };
    this.saveClicked.emit(newTaskDefinition);
  }

  openAddInputParameterDialog(): void {
    const dialogRef = this.dialog.open(AddInputParameterDialogComponent, {
      width: '430px',
    });
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          const group = TaskDefinitionFormComponent.getInputParameterFormGroup(this.destroyed$);
          (group.controls.settings as FormGroup).controls.id.setValue(result.id);
          (group.controls.settings as FormGroup).controls.name.setValue(result.name);
          (group.controls.settings as FormGroup).controls.dataType.setValue(result.dataType);
          group.controls.pathKey.setValue(result.pathKey);
          group.controls.pathValue.setValue(result.pathValue);
          group.controls.required.setValue(result.required);
          group.controls.validationType.setValue(result.validationType);

          this.inputParametersFormArray().push(group);
        }
      });
  }

  testInputParameters(): void {
    const toTest = this.inputParametersFormArray().controls.reduce((prev, curr) => {
      const group = curr as FormGroup;
      prev[group.controls.pathKey.value] = group.controls.pathValue.value;
      return prev;
    }, {});
    this.dialog.open(JsonPathTesterDialogComponent, {
      data: {
        toTest: toTest,
      },
    });
  }

  public import(): void {
    const dialogRef = this.dialog.open(ImportFromJsonDialogComponent);
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((res) => {
        if (res) {
          this.populateFormFromTaskDefinition(res);
        }
      });
  }
}

function taskTypeValidator(control: AbstractControl): ValidationErrors | null {
  if (taskTypeLabels[control.value]) {
    return null;
  }
  return { invalid: true };
}

function methodValidator(control: AbstractControl): ValidationErrors | null {
  if (!!control.value && control.value !== 'POST') {
    return { invalid: true };
  }
  return null;
}

//nonNegativeIntegerValidator validates that the input contains only integers and not negative
function nonNegativeIntegerValidator(control: AbstractControl): { [key: string]: any } {
  if (!control.value) {
    return null;
  }
  const r = new RegExp(/^[0-9]+$/);
  return r.test(control.value) ? null : { pattern: 'invalid' };
}

function validationTypeValidator(control: AbstractControl): ValidationErrors | null {
  if (validationTypeLabels[control.value]) {
    return null;
  }
  return { invalid: true };
}

function dataTypeValidator(control: AbstractControl): ValidationErrors | null {
  if (dataTypeLabels[control.value]) {
    return null;
  }
  return { invalid: true };
}

function getVarNameFromEventTriggerDefinitionParamPathKey(key: string): string {
  if (key.endsWith('.$')) {
    return key.replace('.$', '');
  }
  return key;
}
