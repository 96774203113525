<h2 mat-dialog-title>Import</h2>
<mat-dialog-content>
  <div class="text-content">
    <EXP__glxy-wrap>
      <mat-form-field appearance="outline">
        <mat-label>Paste your data here</mat-label>
        <textarea
          matInput
          [formControl]="dataControl"
          rows="30"
          tabindex="1"
        ></textarea>
        <mat-error *ngIf="dataControl.hasError('required')">Required</mat-error>
        <mat-error *ngIf="dataControl.hasError('json')">
          Invalid json data provided
        </mat-error>
      </mat-form-field>
    </EXP__glxy-wrap>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-stroked-button mat-dialog-close tabindex="3">Close</button>
  <button
    mat-raised-button
    color="primary"
    tabindex="2"
    (click)="import()"
    [disabled]="!this.dataControl.valid"
  >
    Import
  </button>
</mat-dialog-actions>
