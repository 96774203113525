import { EventEmitter, Input, Directive } from '@angular/core';
import { ColumnDefinition, CustomCellComponent } from '@vendasta/va-filter2-table';
import { EventTriggerDefinitionRow } from '../event-trigger-definitions-table.service';

@Directive()
export abstract class EventTriggerDefinitionTableCustomComponent
  implements CustomCellComponent<EventTriggerDefinitionRow>
{
  @Input() columnDefinition: ColumnDefinition;
  @Input() element: EventTriggerDefinitionRow;
  @Input() eventEmitter: EventEmitter<any>;
}
