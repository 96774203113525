import { Component, OnInit } from '@angular/core';
import { TaskDefinitionsTableCustomComponent } from './common-cell.component';
import { supportedContextsLabels } from '../../../../common/constants';

@Component({
  template: `
    <span *ngFor="let context of contexts">{{ context }}</span>
  `,
})
export class SupportedContextsCellComponent extends TaskDefinitionsTableCustomComponent implements OnInit {
  contexts: string[];

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.contexts = this.element.taskDefinition.supportedContexts.map((context) => supportedContextsLabels[context]);
  }
}
