<div class="rules-container" *ngIf="fg.controls.rulesInput.controls.length > 0">
  <div class="and">
    <ng-container *ngFor="let rule of fg.controls.rulesInput.controls; let i = index; let last = last">
      <ng-container *ngIf="rule.value?.comparison === ComparisonAnd || rule.value?.comparison === ComparisonOr">
        <div class="nested-group">
          <app-and-or-input [formControl]="rule" />
        </div>
      </ng-container>
      <ng-container *ngIf="rule.value?.comparison !== ComparisonAnd && rule.value?.comparison !== ComparisonOr">
        <app-rule-input [formControl]="rule" (remove)="removeRule(i)" />
        <div class="and-label" *ngIf="!last">
          <mat-button-toggle-group [(value)]="operator" [formControl]="fg.controls.andOrInput">
            <mat-button-toggle [value]="ComparisonAnd">And</mat-button-toggle>
            <mat-button-toggle [value]="ComparisonOr">Or</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <button class="add-rule" mat-stroked-button (click)="addRule()">Add Single Rule</button>
  <button class="add-rule" mat-stroked-button (click)="addAnd()">Add New Group</button>
</div>
<button
  *ngIf="fg.controls.rulesInput.controls.length === 0"
  class="add-first-rule"
  mat-stroked-button
  (click)="addRule()"
>
  Add an And rule
</button>
