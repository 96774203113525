import { EventEmitter, Input, Directive } from '@angular/core';
import { ColumnDefinition, CustomCellComponent } from '@vendasta/va-filter2-table';
import { TaskDefinitionRow } from '../task-definitions-table.service';

@Directive()
export abstract class TaskDefinitionsTableCustomComponent implements CustomCellComponent<TaskDefinitionRow> {
  @Input() columnDefinition: ColumnDefinition;
  @Input() element: TaskDefinitionRow;
  @Input() eventEmitter: EventEmitter<any>;
}
