<glxy-page>
  <glxy-page-toolbar>
    <glxy-page-nav />
    <glxy-page-title>List Event Trigger Definitions</glxy-page-title>
    <glxy-page-actions>
      <button [routerLink]="['/automations/event-trigger-definitions/create']" mat-stroked-button>
        Create Event Trigger Definition
      </button>
    </glxy-page-actions>
  </glxy-page-toolbar>
  <glxy-page-wrapper maxWidth="1280">
    <va-filtered-mat-table #filteredMatTableComponent [tableDataService]="dataService" />
  </glxy-page-wrapper>
</glxy-page>
