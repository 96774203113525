import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import {
  AutomataDevelopmentService,
  EventTriggerDefinitionInterface,
  TaskDefinitionInterface,
} from '@vendasta/automata';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';
import { map, shareReplay, switchMap, take } from 'rxjs/operators';
import { EventTriggerDefinitionFormComponent } from '../event-trigger-definition-form/event-trigger-definition-form.component';

@Component({
  selector: 'app-edit-event-trigger-definition-page',
  templateUrl: './edit-event-trigger-definition-page.component.html',
  styleUrls: ['./edit-event-trigger-definition-page.component.scss'],
})
export class EditEventTriggerDefinitionPageComponent implements OnInit {
  private eventTriggerDefinitionId$: Observable<string>;
  eventTriggerDefinition$: Observable<TaskDefinitionInterface>;

  @ViewChild('eventTriggerDefinitionForm') eventTriggerDefinitionForm: EventTriggerDefinitionFormComponent;
  saveDisabled = false;

  constructor(
    private route: ActivatedRoute,
    private readonly automataDevelopmentService: AutomataDevelopmentService,
    private router: Router,
    private alertService: SnackbarService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.eventTriggerDefinitionId$ = this.route.params.pipe(
      take(1),
      map((params) => params.eventTriggerDefinitionId),
    );
    this.eventTriggerDefinition$ = this.eventTriggerDefinitionId$.pipe(
      take(1),
      switchMap((id) => this.automataDevelopmentService.getEventTriggerDefinition(id)),
      shareReplay(1),
    );
  }

  cancelClicked(value: boolean): void {
    if (value) {
      this.router.navigate(['/automations/event-trigger-definitions/list']);
    }
  }

  saveClicked(value: EventTriggerDefinitionInterface): void {
    if (!value) {
      this.alertService.openErrorSnack(`I don't know how we got here, that's on you to figure out`);
      return;
    }
    this.saveDisabled = true;
    this.automataDevelopmentService
      .updateEventTrigger(value, {
        paths: ['Name', 'ParametersPaths', 'TriggerContextIDFormat', 'ParameterFilterKeys', 'FlattenedConfig'],
      })
      .pipe(take(1))
      .subscribe(
        () => {
          this.saveDisabled = false;
          this.alertService.openSuccessSnack('Event trigger definition successfully edited');
          this.cdr.detectChanges();
        },
        (err) => {
          console.error(err);
          this.saveDisabled = false;
          this.alertService.openErrorSnack(err?.error?.message);
          this.cdr.detectChanges();
        },
      );
  }

  copyToClipboard(): void {
    this.eventTriggerDefinition$.pipe(take(1)).subscribe((etd) => {
      const selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = JSON.stringify(etd, null, 4);
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);

      this.alertService.openSuccessSnack('Copied');
    });
  }
}
