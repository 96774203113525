import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlSegment } from '@angular/router';
import { TaskDefinitionsListPageComponent } from './task-definitions/task-definitions-list-page/task-definitions-list-page.component';
import { EventTriggerDefinitionsListPageComponent } from './event-trigger-definitions/event-trigger-definitions-list-page/event-trigger-definitions-list-page.component';
import { EditTaskDefinitionPageComponent } from './task-definitions/edit-task-definition-page/edit-task-definition-page.component';
import { CreateTaskDefinitionPageComponent } from './task-definitions/create-task-definition-page/create-task-definition-page.component';
import { EditEventTriggerDefinitionPageComponent } from './event-trigger-definitions/edit-event-trigger-definition-page/edit-event-trigger-definition-page.component';
import { CreateEventTriggerDefinitionPageComponent } from './event-trigger-definitions/create-event-trigger-definition-page/create-event-trigger-definition-page.component';

const routes: Routes = [
  { path: 'task-definitions/list', component: TaskDefinitionsListPageComponent, children: [] },
  {
    path: 'task-definitions/edit/:taskDefinitionId',
    component: EditTaskDefinitionPageComponent,
    children: [],
  },
  {
    path: 'task-definitions/create',
    component: CreateTaskDefinitionPageComponent,
    children: [],
  },
  { path: 'event-trigger-definitions/list', component: EventTriggerDefinitionsListPageComponent, children: [] },
  {
    path: 'event-trigger-definitions/edit',
    children: [
      {
        matcher: (segments) => {
          if (segments.length > 0) {
            let eventTriggerDefinitionId = segments.map((s) => s.path).join('/');
            if (!eventTriggerDefinitionId.startsWith('/')) {
              // Router linking to this page leaves the slash in the id, but it is lost when directly loading the page
              eventTriggerDefinitionId = '/' + eventTriggerDefinitionId;
            }
            return {
              consumed: segments,
              posParams: {
                eventTriggerDefinitionId: new UrlSegment(eventTriggerDefinitionId, {}),
              },
            };
          }
          return null;
        },
        component: EditEventTriggerDefinitionPageComponent,
      },
    ],
  },
  {
    path: 'event-trigger-definitions/create',
    component: CreateEventTriggerDefinitionPageComponent,
    children: [],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AutomationsRoutingModule {}
