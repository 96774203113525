import { CommonModule } from '@angular/common';
import { Component, DestroyRef, forwardRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  ControlValueAccessor,
  FormArray,
  FormControl,
  FormGroup,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { And, Comparison, Or, Rule, RuleInterface } from '@vendasta/automata';
import { GalaxyFormFieldModule } from '@vendasta/galaxy/form-field';
import { map } from 'rxjs/operators';
import { RuleInputComponent } from '../rule-input/rule-input.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

@Component({
  selector: 'app-and-or-input',
  templateUrl: './and-or-input.component.html',
  styleUrls: ['./and-or-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AndOrInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AndOrInputComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [
    CommonModule,
    GalaxyFormFieldModule,
    FormsModule,
    MatInputModule,
    ReactiveFormsModule,
    MatOptionModule,
    MatSelectModule,
    MatCheckboxModule,
    RuleInputComponent,
    MatButtonModule,
    MatIconModule,
    MatButtonToggleModule,
  ],
})
export class AndOrInputComponent implements ControlValueAccessor, Validator, OnInit {
  ComparisonAnd = Comparison.And;
  ComparisonOr = Comparison.Or;
  operator = Comparison.And;

  fg = new FormGroup({
    andOrInput: new FormControl<Comparison.And | Comparison.Or>(Comparison.And),
    rulesInput: new FormArray<FormControl<RuleInterface>>([]),
  });

  constructor(private readonly destroyRef: DestroyRef) {}

  ngOnInit() {
    // This is required because the toggle button doesn't change when the form value changes so we need to use both form values for saving and [(value)] for updating the ui
    this.fg.controls.andOrInput.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value) => {
      this.operator = value;
    });
  }

  registerOnChange(fn: any): void {
    this.fg.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        map(() => {
          if (this.fg.controls.rulesInput.controls.length === 0) {
            return null;
          }
          let value = null;
          if (this.fg.controls.andOrInput.value === Comparison.And) {
            const and = new And(
              this.fg.controls.rulesInput.controls.filter((c) => !!c.value).map((c) => Rule.fromRuleInterface(c.value)),
              'start',
            );
            value = and.toRuleInterface();
          }
          if (this.fg.controls.andOrInput.value === Comparison.Or) {
            const or = new Or(
              this.fg.controls.rulesInput.controls.filter((c) => !!c.value).map((c) => Rule.fromRuleInterface(c.value)),
              'start',
            );
            value = or.toRuleInterface();
          }
          return value;
        }),
      )
      .subscribe((value) => {
        fn(value);
      });
  }

  registerOnTouched(fn: any): void {
    this.fg.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(fn);
  }

  writeValue(rule: RuleInterface): void {
    if (!rule) {
      return;
    }
    if (rule.comparison === Comparison.And) {
      const and: And = And.fromRuleInterface(rule) as And;
      and.value
        .map((r) => r.toRuleInterface())
        .map((ri) => this.fg.controls.rulesInput.push(new FormControl<RuleInterface>(ri)));
      this.fg.controls.andOrInput.setValue(Comparison.And);
      return;
    }
    if (rule.comparison === Comparison.Or) {
      const or: Or = Or.fromRuleInterface(rule) as Or;
      or.value
        .map((r) => r.toRuleInterface())
        .map((ri) => this.fg.controls.rulesInput.push(new FormControl<RuleInterface>(ri)));
      this.fg.controls.andOrInput.setValue(Comparison.Or);
      return;
    }
    alert('Only AND/OR rules are allowed');
    return;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  validate(control: AbstractControl): ValidationErrors | null {
    return this.fg.invalid ? { invalid: true } : null;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.fg.disable();
    } else {
      this.fg.enable();
    }
  }

  addRule() {
    this.fg.controls.rulesInput.push(new FormControl<RuleInterface>(null, [Validators.required]));
  }

  addAnd() {
    this.fg.controls.rulesInput.push(
      new FormControl<RuleInterface>({ comparison: Comparison.And }, [Validators.required]),
    );
  }

  removeRule(index: number): void {
    this.fg.controls.rulesInput.removeAt(index);
  }
}
